import { Typography } from "@mui/material";
import React from "react";
import { formatDate } from "shared/utils/date_format";
import styles from "./Table.module.css";

const getFormattedDate = (date, format) => {
    if (format === "extended") {
        return new Date(date)?.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "numeric",
            year: "numeric",
        });
    }
    if (format === "short") {
        return date ? formatDate(date) : "No Activity yet";
    }
    return date;
};

export const TableDate = ({ date = new Date(), dateFormat = "extended" }) => {
    if (!date) throw new Error("Date not defined");
    return (
        <Typography className={styles["gs-table-primary-text"]}>
            {getFormattedDate(date, dateFormat)}
        </Typography>
    );
};
