import JoiLib from "joi";

export const Joi = JoiLib.defaults((schema) =>
    schema.options({
        errors: {
            wrap: {
                label: false,
            },
        },
        abortEarly: false,
        allowUnknown: true,
    }),
);
