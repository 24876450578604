import React from "react";
import { ContentWrapper, TableCard } from "shared/Components";
import { PaymentProvider, usePayment } from "./hooks/usePayment";
import styles from "./styles/Payment.module.css";
import DateRangePicker from "shared/Components/DateRangePicker/DateRangePicker";

const Payment = ({ title }) => {
    const {
        columns,
        row,
        getDateRangePickerProps,
        getPaymentLoading,
        handlePageChange,
        page,
        count,
        handlePageSizeChange,
        sizePerPage,
    } = usePayment();

    return (
        <>
            <ContentWrapper
                title={title}
                sideButton={<DateRangePicker {...getDateRangePickerProps()} />}
            >
                <div className={styles["gs-payment-response-table"]}>
                    <TableCard
                        showNoData={false}
                        primaryClassName={styles["table"]}
                        resize
                        columnHeader={styles["table-header"]}
                        loading={getPaymentLoading}
                        keyField={"transaction_id"}
                        columns={columns}
                        data={row}
                        hasCustomHeader={true}
                        columnsheader={styles["table-payment-header"]}
                        columnsHeaderClass={styles["table-header-wrap"]}
                        headerHeight={130}
                        disableSorting={true}
                        paginationProps={{
                            rowCount: count,
                            page: page,
                            pageSize: sizePerPage,
                            onPageChange: handlePageChange,
                            onPageSizeChange: handlePageSizeChange,
                            paginationMode: "server",
                        }}
                        checkboxSelection={false}
                    ></TableCard>
                </div>
            </ContentWrapper>
        </>
    );
};

export const Wrapper = (props) => (
    <PaymentProvider>
        <Payment {...props} />
    </PaymentProvider>
);

export default Wrapper;
