import React from "react";
import { MUITabs, TabPanel } from "..";
import styles from "./TabStripVertical.module.css";

const Panel = ({ value, children, ...rest }) => {
    return (
        <TabPanel className={styles["gs-tab-tabpanel"]} value={value} {...rest}>
            {children}
        </TabPanel>
    );
};

// const Tabs = ({ value, children, lable, ...rest }) => {
//   return (
//     <MUITab classes={{ root: styles["gs-tab-Data"] }} label={lable} {...rest}>
//       {children}
//     </MUITab>
//   );
// };
export const TabVertical = ({ children, className, onChange, buttons, ...rest }) => {
    return (
        <MUITabs
            onChange={onChange}
            aria-label="basic tabs example"
            classes={{
                root: buttons ? styles["gs-button-tabs"] : styles["gs-tabs"],
                indicator: `${styles["gs-tabs-indicator"]}`,
            }}
            className={className}
            TabIndicatorProps={{ children: <span className={styles.indicator} /> }}
            {...rest}
            orientation="vertical"
            // variant="scrollable" // vartical tabs props to use vertical tabe
        >
            {children}
        </MUITabs>
    );
};
// Tab.Tabs = Tabs;
TabVertical.Panel = Panel;
