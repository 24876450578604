import React from "react";

import { FormControlLabel, MUICheckbox } from "../";

import styles from "./CheckBox.module.css";
import { CircularProgress } from "@mui/material";

const CheckBox = ({
    label,
    disabled,
    defaultChecked,
    checked,
    unfillBorder = "var(--secondary-light-color)",
    accentColor = "var(--primary-color)",
    onChange,
    className = "",
    labelClassName = "",
    inputClassName = "",
    labelColor,
    loading = false,
    ...rest
}) => {
    if (loading) {
        return <CircularProgress size={20} sx={{ color: "var(--primary-color)" }} />;
    }
    return (
        <FormControlLabel
            className={`${styles.checkbox} ${className}`}
            control={
                <MUICheckbox
                    className={`${inputClassName}`}
                    sx={{
                        color: unfillBorder,
                        marginRight: "0px",
                        "&.Mui-checked": {
                            color: accentColor,
                        },
                    }}
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    checked={checked}
                    onChange={onChange}
                    {...rest}
                    labelStyle={{ color: "white" }}
                />
            }
            label={
                <span className={labelClassName} style={{ color: labelColor }}>
                    {label}
                </span>
            }
        />
    );
};

export { CheckBox };
