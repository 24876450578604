import React from "react";
import styles from "./CustomHeader.module.css";
import { MenuItem, Typography } from "@mui/material";
import { Dropdown, TextField } from "..";

const CustomHeader = ({
    headerName,
    isDropdownFilter = false,
    conditions,
    setConditions,
    fieldKey,
    refetch,
}) => {
    const handleSearch = (e) => {
        const temp = [...conditions];
        const index = temp.findIndex((item) => item?.field === fieldKey);
        if (index > -1) {
            temp[index].search = e.target.value;
            setConditions(temp);
            refetch();
        }
    };
    const paymentGateway = [
        {
            id: 1,
            text: "All",
            value: "",
        },
        {
            id: 2,
            text: "Stripe",
            value: "stripe",
        },
    ];
    // const debounceSearch = _.debounce(handleSearch, 1000);
    return (
        <>
            <div className={styles["gs-payment-header"]}>
                <div className={styles["gs-payment-header-name"]}>
                    <Typography> {headerName} </Typography>
                </div>
                <div className={styles["gs-payment-header-search"]}>
                    {isDropdownFilter ? (
                        <Dropdown
                            className={styles["payment-dropdown"]}
                            onChange={(e) => handleSearch(e)}
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <p style={{ color: "#E3E8EC" }}>Please select...</p>;
                                } else {
                                    return selected;
                                }
                            }}
                        >
                            {paymentGateway?.map((data) => {
                                return (
                                    <MenuItem key={data.id} value={data?.value}>
                                        {data?.text}
                                    </MenuItem>
                                );
                            })}
                        </Dropdown>
                    ) : (
                        <TextField
                            style={{ marginTop: "10px" }}
                            placeholder="search"
                            className={styles["payment-column-header-search"]}
                            onChange={(e) => handleSearch(e)}
                        ></TextField>
                    )}
                </div>
            </div>
        </>
    );
};

export default CustomHeader;
