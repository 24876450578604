import React from "react";
import styles from "./ErrorMessage.module.css";

export const ErrorMessage = ({ helperClassName = "", message = "" }) => {
    return (
        <div className={`${styles["helper-text"]} ${helperClassName}`}>
            <p>{message}</p>
        </div>
    );
};
