import React from "react";

import styles from "./index.module.css";

const Header = ({ children, className = "", withPadding = true, ...rest }) => {
    const additionalClasses = `${withPadding ? "" : "p-0"} ${className}`;

    return (
        <div className={`${styles.header} ${additionalClasses}`} {...rest}>
            {children}
        </div>
    );
};

const Body = ({ children, className = "", withPadding = true, ...rest }) => {
    const additionalClasses = `${withPadding ? "p-20" : "p-0"} ${className}`;

    return (
        <div className={`${styles.body} ${additionalClasses}`} {...rest}>
            {children}
        </div>
    );
};

const Card = ({ children, className = "", ...rest }) => {
    return (
        <div className={`${styles.card} ${className}`} {...rest}>
            {children}
        </div>
    );
};

Card.Header = Header;
Card.Body = Body;

export default Card;
