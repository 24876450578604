/* eslint-disable no-unused-vars */
import React, { forwardRef } from "react";
import styles from "../styles/Login.module.css";
import { TextField as MUITextField } from "@mui/material";
import { Box, ErrorMessage } from "shared/Components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Controller, useFormContext } from "react-hook-form";

const LoginTextField = forwardRef(
    (
        {
            fullWidth = true,
            placeholder,
            inValid = false,
            valid = false,
            value,
            onChange,
            label,
            // helperText,
            type = "text",
            multiline = false,
            rows = 4,
            size = "small",
            className = "",
            InputProps = {},
            require = false,
            helperClassName = "",
            labelTextClassName = "",
            name,
            ...rest
        },
        ref,
    ) => {
        const { control } = useFormContext();
        const inValidClassName = (invalid) => (require && invalid ? styles.error : "");
        const validClassName = (invalid, isDirty) =>
            require && !invalid && isDirty ? styles.success : "";

        return (
            <Controller
                name={name}
                control={control}
                render={({
                    field: { ref: formInputRef, ...field },
                    fieldState: { invalid, isDirty, error, isTouched },
                }) => (
                    <Box
                        sx={fullWidth ? { width: "100%" } : null}
                        className={`${styles.wrapper} ${inValidClassName(
                            invalid,
                            isDirty,
                            error,
                            isTouched,
                        )} ${validClassName(invalid, isDirty, error, isTouched)} ${className}`}
                    >
                        {/* {label && (
						<label className={`${styles.label} ${labelTextClassName}`}>
							{label} {require && <span style={{ color: "var(--danger-color)" }}>{" *"}</span>}
						</label>
					)} */}
                        <MUITextField
                            // inputRef={ref}
                            name={name}
                            type={type}
                            className={styles["login-text-field"]}
                            value={value}
                            error={inValid}
                            onChange={onChange}
                            fullWidth={fullWidth}
                            variant="outlined"
                            placeholder={placeholder}
                            size={size}
                            label={
                                label && (
                                    <label className={`${styles.label} ${labelTextClassName}`}>
                                        {label}{" "}
                                        {require && (
                                            <span style={{ color: "var(--danger-color)" }}>
                                                {" *"}
                                            </span>
                                        )}
                                    </label>
                                )
                            }
                            {...(multiline && {
                                multiline: true,
                                rows,
                            })}
                            InputProps={{
                                ...(valid && {
                                    endAdornment: (
                                        <CheckCircleOutlineOutlinedIcon
                                            className={styles["success-icon"]}
                                        />
                                    ),
                                }),
                                ...InputProps,
                            }}
                            inputProps={{ ...rest }}
                            {...field}
                            {...rest}
                        />
                        {!!error && (
                            <ErrorMessage
                                helperClassName={helperClassName}
                                message={error?.message}
                            />
                        )}
                        {/* {!!helperText && (
						<div className={styles["helper-text"]}>
							<p>{helperText}</p>
						</div>
					)} */}
                    </Box>
                )}
            />
        );
    },
);

export { LoginTextField };
