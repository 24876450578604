import React, { createContext, useContext, useState } from "react";
import { Box, CircularProgress, FormControl, ListSubheader, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import styles from "./Dropdown.module.css";
import { forwardRef } from "react";
import { SearchIcon } from "shared/Assets/Icons";
import { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { isFunc } from "shared/utils";

const ctx = createContext();

const FDropdownItem = ({ children, ...rest }) => {
    const { size } = useContext(ctx);

    return (
        <MenuItem className={`${size === "small" ? styles["small-menu-item"] : ""}`} {...rest}>
            {children}
        </MenuItem>
    );
};

export const Dropdown = forwardRef(
    (
        {
            // required
            children,
            // optional
            size = "medium", // small, medium
            fullWidth = true,
            outlined = true,
            className = "",
            border = "",
            height = "",
            backgroundColor: bgColor = "var(--white-color)",
            // inValid = false,
            // valid = false,
            label,
            // helperText,
            dropdownClassName = "",
            renderValue,
            labelClassName,
            selectClassName,
            require = false,
            menuAutoFocus = false,
            popOverClassName,
            IconComponent = KeyboardArrowDownIcon,
            menuprops = {},
            name,
            onChange,
            disabled = false,
            inputProps,
            ...rest
        },
        ref,
    ) => {
        const backgroundColor = outlined ? bgColor : "var(--gray-bk-color)";

        const inValidClassName = (invalid) => (require && invalid ? styles.error : "");
        const validClassName = (invalid, isDirty) =>
            require && !invalid && isDirty ? styles.success : "";

        const formContext = useFormContext();

        const { control } = formContext;

        function onChangeHandler(event, field) {
            field.onChange(event);
            if (isFunc(onChange)) {
                onChange(event);
            }
        }

        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error, invalid, isDirty, isTouched } }) => (
                    <Box
                        className={`${styles.wrapper} ${inValidClassName(
                            invalid,
                            isDirty,
                            error,
                            isTouched,
                        )} ${validClassName(invalid, isDirty, error, isTouched)} ${className} ${
                            styles["gs-simple-dropdown"]
                        }`}
                    >
                        {label && (
                            <label className={`${styles.label} ${labelClassName}`}>
                                {label}
                                {require && (
                                    <span style={{ color: "var(--danger-color)" }}>{" *"}</span>
                                )}
                            </label>
                        )}
                        <ctx.Provider value={{ size }}>
                            <FormControl fullWidth={fullWidth} error disabled={disabled}>
                                <Select
                                    {...field}
                                    value={field.value ?? ""}
                                    onChange={(e) => onChangeHandler(e, field)}
                                    ref={ref}
                                    variant="outlined"
                                    size={size}
                                    fullWidth={fullWidth}
                                    style={{
                                        backgroundColor,
                                        border,
                                        height,
                                    }}
                                    className={`${styles["gs-dropdown-select"]} ${dropdownClassName} ${selectClassName} `}
                                    classes={{
                                        select: styles.select,
                                        icon: styles.icon,
                                    }}
                                    IconComponent={IconComponent}
                                    MenuProps={{
                                        className: `${styles["gs-dropdown-popover-menu"]} ${popOverClassName}`,
                                        autoFocus: menuAutoFocus,
                                        ...menuprops,
                                    }}
                                    renderValue={renderValue}
                                    inputProps={inputProps}
                                    disabled={disabled}
                                    {...rest}
                                >
                                    {children}
                                </Select>
                            </FormControl>
                        </ctx.Provider>
                        {!!error && (
                            <div className={styles["helper-text"]}>
                                {/* <InfoOutlinedIcon /> */}
                                <p>{error?.message}</p>
                            </div>
                        )}
                    </Box>
                )}
            />
        );
    },
);

export const SearchableDropdown = forwardRef((props, ref) => {
    const [search, setSearch] = useState("");
    if (!isFunc(props.children)) throw Error("Children has to be a function.");

    const handleChange = (e) => setSearch(e.target.value);

    const searchInputRef = useRef(null);

    return (
        <Dropdown
            onOpen={() => {
                setTimeout(() => {
                    searchInputRef?.current?.focus();
                }, 500);
            }}
            onClose={() => {
                setTimeout(() => {
                    setSearch("");
                }, 100);
            }}
            {...props}
            ref={ref}
        >
            {props?.loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80px",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <ListSubheader>
                    <div className={styles["gs-dropdown-search-input"]}>
                        <SearchIcon width={"14px"} />
                        <input
                            ref={searchInputRef}
                            onChange={handleChange}
                            value={search}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                    </div>
                </ListSubheader>
            )}
            {props?.allOption ? <Dropdown.Item value="none">None</Dropdown.Item> : null}
            {props?.allOption ? <Dropdown.Item value="all">All</Dropdown.Item> : null}
            {props.children({ search })}
        </Dropdown>
    );
});

Dropdown.Item = FDropdownItem;
