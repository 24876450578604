import React from "react";
import { MuiBreadcrumbs } from "..";
import styles from "./Breadcrumbs.module.css";

export const Breadcrumbs = (props) => {
    const { children, ...rest } = props;
    return (
        <div>
            <MuiBreadcrumbs classes={{ root: styles["gs-breadcrumbs"] }} {...rest}>
                {children}
            </MuiBreadcrumbs>
        </div>
    );
};
