import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormControlLabel, MUICheckbox } from "..";

import styles from "./CheckBox.module.css";

const CheckBox = ({
    label,
    disabled,
    defaultChecked,
    // checked,
    unfillBorder = "var(--secondary-light-color)",
    accentColor = "var(--primary-color)",
    // onChange,
    className = "",
    labelClassName = "",
    labelColor,
    name,
    ...rest
}) => {
    const { control } = useFormContext();
    return (
        <FormControlLabel
            className={`${styles.checkbox} ${className}`}
            control={
                <Controller
                    control={control}
                    name={name}
                    render={({ field }) => (
                        <MUICheckbox
                            sx={{
                                color: unfillBorder,
                                marginRight: "0px",
                                "&.Mui-checked": {
                                    color: accentColor,
                                },
                            }}
                            disabled={disabled}
                            defaultChecked={defaultChecked}
                            checked={field.value}
                            // onChange={onChange}
                            {...field}
                            {...rest}
                            labelStyle={{ color: "white" }}
                        />
                    )}
                />
            }
            label={
                <span className={labelClassName} style={{ color: labelColor }}>
                    {label}
                </span>
            }
        />
    );
};

export { CheckBox };
