import * as React from "react";
import Product from "./Routes/Product";
import { QueryClient, QueryClientProvider } from "react-query";

import { LicenseInfo } from "@mui/x-license-pro";

import "shared/Assets/Styles/Common.css";
import "./App.css";
import { ApolloProvider } from "@apollo/client";
import { client } from "ApolloClient";
import { BrowserRouter as Router } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthProvider } from "modules/Authentication/hooks/UseLogin";
import { useProduct } from "shared/Hooks";
// import { StyledEngineProvider } from "@mui/material";

export const ProductContext = React.createContext();
const queryClient = new QueryClient();

LicenseInfo.setLicenseKey(
    // "178e96d540442d994dd5c0765feecd8bTz01NTM5NSxFPTE3MDE1MTQ3NjM2NTQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    process.env.REACT_APP_LICENSE_KEY,
);

const stripePromise = loadStripe(
    process.env.REACT_APP_ENV === "development"
        ? process.env.REACT_APP_TEST_STRIPE_PUBLISH_KEY
        : process.env.REACT_APP_LIVE_STRIPE_PUBLISH_KEY,
);

export default function App() {
    const { value } = useProduct();
    return (
        // <StyledEngineProvider injectFirst>
        <Router>
            <Elements stripe={stripePromise}>
                <ProductContext.Provider value={value}>
                    <ApolloProvider client={client}>
                        <QueryClientProvider client={queryClient}>
                            <AuthProvider>
                                <Product />
                            </AuthProvider>
                        </QueryClientProvider>
                    </ApolloProvider>
                </ProductContext.Provider>
            </Elements>
        </Router>
        // </StyledEngineProvider>
    );
}
