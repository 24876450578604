import React from "react";
import { FormProvider as Form } from "react-hook-form";

export const FormProvider = ({ methods, children, onSubmit, className }) => {
    return (
        <Form {...methods}>
            <form noValidate className={className} onSubmit={onSubmit} autoComplete="off">
                {children}
            </form>
        </Form>
    );
};
