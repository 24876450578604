import { useEffect } from "react";
import { useState } from "react";

/**
 * @description Custom hook for handling pagination in a table.
 * @param {object} paginationProps - Object containing pagination properties.
 * @param {string} paginationProps.defaultSortField - Default field to sort the table by. Defaults to an empty string.
 * @param {string} paginationProps.defaultSortOrder - Default sort order. Defaults to "asc".
 *
 * @returns {object} An object containing pagination state and handler functions.
 * @property {number} page - The current page number.
 * @property {function} setPage - Function to set the current page number.
 * @property {number} sizePerPage - The number of entries per page.
 * @property {function} setSizePerPage - Function to set the number of entries per page.
 * @property {function} handlePageChange - Function to handle a change in the page number.
 * @property {function} handlePageSizeChange - Function to handle a change in the number of entries per page.
 * @property {object} DEFAULT_GRAPHQL_PAGINATION_VARIABLES - Default GraphQL pagination variables.
 * @property {number} DEFAULT_GRAPHQL_PAGINATION_VARIABLES.skip - Number of entries to skip based on the current page and sizePerPage.
 * @property {number} DEFAULT_GRAPHQL_PAGINATION_VARIABLES.take - Number of entries to take based on the sizePerPage.
 * @property {function} handleSortChange - Function to handle a change in the sorting options.
 * @property {object[]} sortOptions - Array of sorting options.
 * @property {string} sortOptions[].field - Name of the field to sort in the table.
 * @property {string} sortOptions[].sort - Type of sort (e.g., "asc" or "desc").
 */
export const usePagination = (paginationProps = {}, searchText = "") => {
    const { defaultSortField = "", defaultSortOrder = "" } = paginationProps;
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [sortOptions, setSortOptions] = useState([
        {
            field: defaultSortField,
            sort: defaultSortOrder,
        },
    ]);

    /**
     * @param {object} newVal - Object contains field to sort and sort type
     * @param {string} field - Name of the field to sort in table
     * @param {string} sort - Type of sort (e.g asc and desc)
     */
    const handleSortChange = (newVal) => {
        setSortOptions(newVal);
    };

    const DEFAULT_GRAPHQL_PAGINATION_VARIABLES = {
        skip: searchText ? 0 : page * sizePerPage,
        take: searchText ? 0 : sizePerPage,
        search_text: searchText,
        // sort_model: sortOptions,
    };

    useEffect(() => {
        setPage(0);
    }, [searchText]);

    /**
     * @param {number} newVal - Page number
     */
    const handlePageChange = (newVal) => {
        setPage(newVal);
    };

    /**
     * @param {number} newSize - Size of page entries in table
     */
    const handlePageSizeChange = (newSize) => {
        setSizePerPage(newSize);
    };

    return {
        page,
        setPage,
        sizePerPage,
        setSizePerPage,
        handlePageChange,
        handlePageSizeChange,
        DEFAULT_GRAPHQL_PAGINATION_VARIABLES,
        handleSortChange,
        sortOptions,
    };
};
