import React from "react";
import { MUIAccordion, AccordionSummary, AccordionDetails } from "..";
import styles from "./Accordion.module.css";

const Summary = ({ expandIcon, children, customeComponents, className, ...rest }) => {
    return (
        <AccordionSummary
            classes={{
                root: customeComponents
                    ? styles["gs-accordion-button-wrapper"]
                    : styles["gs-accordion-summary"],
            }}
            expandIcon={expandIcon}
            className={className}
            {...rest}
        >
            {children}
        </AccordionSummary>
    );
};

const Details = ({ children, className, ...rest }) => {
    return (
        <AccordionDetails className={`${styles["gs-accordion-detail"]} ${className}`} {...rest}>
            {children}
        </AccordionDetails>
    );
};

export const Accordions = ({ expanded, className, children, ...rest }) => {
    return (
        <MUIAccordion
            classes={{
                root: styles["gs-accordion"],
            }}
            expanded={expanded}
            className={className}
            {...rest}
        >
            {children}
        </MUIAccordion>
    );
};

Accordions.Summary = Summary;
Accordions.Details = Details;
