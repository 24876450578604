import React from "react";
import styles from "./OtpInputs.module.css";

export const OtpInputs = ({ otp, setOtp, className, formclassName }) => {
    const handleChangeOtp = (e) => {
        const { name, value } = e.target;
        let newInput = e.nativeEvent.data;
        const inputValue = parseInt(newInput);
        if (value && isNaN(inputValue)) {
            return;
        }
        setOtp((prevState) => ({
            ...prevState,
            [name]: !value ? "" : newInput,
        }));
    };

    //?------------------------ INPUT FOCUS FUNCTION [OTP-CONFIRMATION] ------------------------
    const inputFocus = (e) => {
        if (e.key === "Backspace") {
            const prevInput = e.target.form[e.target.tabIndex - 2];
            prevInput?.focus();
        } else if (e.target.value && !isNaN(Number(e.target.value))) {
            const nextInput = e.target.form[e.target.tabIndex];
            nextInput?.focus();
        }
    };

    //?------------------------ PASTE FUNCTION [OTP-CONFIRMATION] ------------------------
    const pasteFun = (e) => {
        const clip = e.clipboardData.getData("text");
        if (!/\d{6}/.test(clip)) return e.preventDefault(); // Invalid. Exit here
        const s = { ...clip };

        const pasteValue = {
            otp1: s[0],
            otp2: s[1],
            otp3: s[2],
            otp4: s[3],
            otp5: s[4],
            otp6: s[5],
        };
        setOtp(pasteValue);

        document.querySelectorAll(`.${styles["gs-change-password-otp"]}`)[5].focus();
    };
    return (
        <form className={`${styles["gs-otp-input-wrapper"]} ${formclassName || ""}`}>
            {Object.keys(otp).map((otpInput, i) => (
                <input
                    className={`${styles["gs-change-password-otp"]} ${className || ""}`}
                    key={i}
                    type={"text"}
                    tabIndex={i + 1}
                    required
                    value={otp[otpInput]}
                    name={otpInput}
                    onChange={(e) => e.preventDefault()}
                    onChangeCapture={handleChangeOtp}
                    onKeyUp={(e) => inputFocus(e)}
                    onPaste={(e) => pasteFun(e)}
                    autoComplete="off"
                    autoFocus={i === 0}
                />
            ))}
        </form>
    );
};
