import React from "react";
import ReactLoading from "react-loading";
import { MUIButton } from "..";
import styles from "./Button.module.css";

export const Button = ({
    disabled = false,
    loading = false,
    loadingColor = "var(--white-color)",
    children,
    className = "gs-primary-button",
    primaryClassName,
    primaryLoadingColor,
    ...rest
}) => {
    const buttonLoadingColor = loading ? primaryLoadingColor : loadingColor;

    return (
        <MUIButton className={`${className} ${primaryClassName}`} disabled={disabled} {...rest}>
            {loading ? (
                <ReactLoading
                    className={styles["gs-button-loader-bubble"]}
                    type={"bubbles"}
                    color={buttonLoadingColor}
                />
            ) : (
                children
            )}
        </MUIButton>
    );
};
