export function formatDate(date) {
    let seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) {
        return new Date(date).toLocaleTimeString(undefined, {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
    }
    if (interval === 1) {
        return Math.floor(interval) + " year ago";
    }
    interval = seconds / 2592000;
    if (Math.round(interval) === 1) {
        return Math.round(interval) + " month ago";
    }
    if (interval > 1) {
        return Math.round(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (Math.round(interval) === 1) {
        return "1 day ago";
    }
    if (interval > 1) {
        return Math.round(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (Math.round(interval) === 1) {
        return "1 hour ago";
    }
    if (interval > 1) {
        return Math.round(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (Math.round(interval) === 1) {
        return "1 minute ago";
    }
    if (interval > 1) {
        return Math.round(interval) + " minutes ago";
    }

    if (Math.round(interval) === 0) {
        return "Just now";
    }
    return Math.round(seconds) + " seconds ago";
}
