import { Routes } from "../Routes/Routes";

export const BreadCrumbData = {
    "/settings": Routes.User.Settings.Profile.ProfileSettings,
    "/home/dashboard": Routes.User.Dashboard,
    "/role-permission": "/settings/role-permission",
    "/survey": "/survey",
    "/checklist": "/checklist",
    "/product-tour": "/product-tour",
    "/applications": "app/applications",
};
