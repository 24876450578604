import React, { useEffect, useRef, useState } from "react";
import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    Pagination,
    Typography,
    Box,
    LinearProgress,
    MuiAvatar,
    Popover,
    IconButton,
    Button,
} from "shared/Components";
import {
    DeleteIcon,
    EditIcon,
    CopyIcon,
    ChecklistIcon,
    Download,
    ResendIcon,
    PublishIcon,
    TestEmailIcon,
    StatusUpdateIcon,
    ViewIcon,
    SendIcon,
} from "shared/Assets/Icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./Table.module.css";
import LaunchIcon from "@mui/icons-material/Launch";
import ReactLoading from "react-loading";

export function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            className="gs-table-pagination"
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(_event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

export const TabPanel = (props) => {
    const { index, value, children, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`gs-simple-tabpanel-${index}`}
            aria-labelledby={`gs-simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: "0px 0px 0px 20px" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export function LinearProgressWithLabel(props) {
    const { label, ...rest } = props;
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                    classes={{ root: styles["gs-progressbar"] }}
                    variant="determinate"
                    {...rest}
                />
            </Box>
            {label && (
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            )}
        </Box>
    );
}

export const Avatar = (props) => {
    const { shape, ...rest } = props;
    return (
        <MuiAvatar {...rest} classes={shape === "square" ? { root: styles["gs-avatar"] } : {}} />
    );
};
export const CustomeActionsCell = ({
    onEdit,
    onDelete,
    onCopy,
    onResend,
    onPrimary,
    onDownload,
    onTestmail,
    onDuplicate,
    onViewScript,
    onPublish,
    onResendQuotation,
    onSendQuotation,
    onStatusUpdate,
    onView,
    onViewJourney,
    publishlable = "Publish",
    disabled = false,
    card,
    loading,
    popoverClassName,
    buttonClassName,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const ref = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (ref?.current !== e.target && !ref?.current?.contains(e.target)) {
                setAnchorEl(null);
            }
        };
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        const clickDiv = document.querySelector(".gs-items");
        if (clickDiv) {
            if (id === "simple-popover") {
                clickDiv.style.overflow = "hidden";
            } else {
                clickDiv.style.overflow = "auto";
            }
        }
    }, [open, id]);

    return (
        <div ref={ref}>
            <IconButton
                disabled={disabled}
                aria-label="settings"
                sx={{ height: "32px", width: "32px" }}
                onClick={(e) => {
                    if (card) {
                        e.stopPropagation();
                    }
                    handleClick(e);
                }}
                // color={"var(--secondary-color)"}
                // style={{ color: "var(--secondary-color)" }}
            >
                {/* <MoreHorizIcon
        // name="anchor-el"
        // stroke="var(--secondary-color)"
        // fill="var(--secondary-color)"
        /> */}
                <MoreVertIcon />
            </IconButton>

            <Popover
                className={`${styles["gs-actions-popover"]} ${popoverClassName}`}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <div className={styles["gs-button-action-wrapper"]}>
                    {onEdit && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<EditIcon />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]}  extraLarge-button ${buttonClassName}`}
                                onClick={onEdit}
                                disabled={disabled}
                            >
                                Edit
                            </Button>
                        </div>
                    )}

                    {onViewScript && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={
                                    <LaunchIcon
                                        sx={{ color: "#646F79", height: 16, cursor: "pointer" }}
                                    />
                                }
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button`}
                                onClick={(e) => {
                                    onViewScript();
                                    setAnchorEl(null);
                                    e.stopPropagation();
                                }}
                                disabled={disabled}
                            >
                                View script
                            </Button>
                        </div>
                    )}
                    {onCopy && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<CopyIcon />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button`}
                                onClick={onCopy}
                                disabled={disabled}
                            >
                                Copy
                            </Button>
                        </div>
                    )}

                    {onResend && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<ResendIcon style={{ marginLeft: "-3px" }} />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button`}
                                onClick={onResend}
                                disabled={disabled}
                            >
                                Resend
                            </Button>
                        </div>
                    )}
                    {onPrimary && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<ChecklistIcon width={"17px"} height="17px" />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button`}
                                onClick={onPrimary}
                                disabled={disabled}
                            >
                                Set primary
                            </Button>
                        </div>
                    )}
                    {onPublish && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<PublishIcon width={"17px"} height="17px" />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button`}
                                onClick={onPublish}
                            >
                                {publishlable}
                            </Button>
                        </div>
                    )}
                    {onDuplicate && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<CopyIcon />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button`}
                                onClick={onDuplicate}
                                disabled={disabled}
                            >
                                Duplicate
                            </Button>
                        </div>
                    )}
                    {onDownload && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<Download />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button`}
                                onClick={onDownload}
                            >
                                Download
                            </Button>
                        </div>
                    )}
                    {onTestmail && (
                        <div
                            className={styles["gs-button-wrapper"]}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                height: "51px",
                            }}
                        >
                            {loading ? (
                                <ReactLoading
                                    className={styles["gs-button-loader-bubble"]}
                                    type={"bubbles"}
                                    color={"var(--primary-color)"}
                                />
                            ) : (
                                <Button
                                    startIcon={<TestEmailIcon width={18} />}
                                    style={{ padding: "11px 27px" }}
                                    className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button`}
                                    // onClick={onTestmail}
                                    onClick={(e) => {
                                        onTestmail();
                                        // setAnchorEl(ref?.current);
                                        e.stopPropagation();
                                    }}
                                >
                                    Test Mail
                                </Button>
                            )}
                        </div>
                    )}
                    {onSendQuotation && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<SendIcon />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button ${buttonClassName}`}
                                onClick={onSendQuotation}
                                disabled={disabled}
                            >
                                send quotation
                            </Button>
                        </div>
                    )}
                    {onResendQuotation && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<ResendIcon />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button ${buttonClassName}`}
                                onClick={onResendQuotation}
                                disabled={disabled}
                            >
                                Re-send quotation
                            </Button>
                        </div>
                    )}
                    {onStatusUpdate && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<StatusUpdateIcon />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button ${buttonClassName}`}
                                onClick={onStatusUpdate}
                                disabled={disabled}
                            >
                                Update status
                            </Button>
                        </div>
                    )}
                    {onView && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<ViewIcon />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button ${buttonClassName}`}
                                onClick={onView}
                                disabled={disabled}
                            >
                                View
                            </Button>
                        </div>
                    )}
                    {onViewJourney && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<ViewIcon />}
                                style={{ padding: "11px 27px" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-action-button"]} extraLarge-button ${buttonClassName}`}
                                onClick={onViewJourney}
                                disabled={disabled}
                            >
                                View journey
                            </Button>
                        </div>
                    )}
                    {onDelete && (
                        <div className={styles["gs-delete-button-wrapper"]}>
                            <Button
                                startIcon={<DeleteIcon />}
                                style={{ padding: "11px 27px", color: "red" }}
                                className={`gs-secondary-other-ghost-button  ${styles["gs-delete-action-button"]} extraLarge-button ${buttonClassName}`}
                                onClick={(e) => {
                                    onDelete();
                                    e.stopPropagation();
                                }}
                                disabled={disabled}
                            >
                                Delete
                            </Button>
                        </div>
                    )}
                </div>
            </Popover>
        </div>
    );
};
