import { ProductContext } from "App";
import React from "react";
import { useContext } from "react";
import styles from "./Loader.module.css";

export const LinearProgressLoader = () => {
    const { showSidebar } = useContext(ProductContext);
    return (
        <div className={styles["gs-kendo-loader-wrapper"]}>
            <div
                className={
                    showSidebar
                        ? styles["table-linear-activity-open"]
                        : styles["table-linear-activity-close"]
                }
            >
                <div className={styles["indeterminate"]}></div>
            </div>
        </div>
    );
};

export const DrawerLinearProgressLoader = () => {
    return (
        <div className={styles["gs-drawer-loader-wrapper"]}>
            <div className={styles["drawer-linear-activity-open"]}>
                <div className={styles["indeterminate"]}></div>
            </div>
        </div>
    );
};

export const ButtonLoader = ({ circleSize = 13, color = "var(--white-color)" }) => (
    <div className={styles["gs-button-loader"]}>
        {[...Array(4).keys()].map((key) => (
            <div
                key={key}
                style={{
                    height: circleSize,
                    width: circleSize,
                    backgroundColor: color,
                }}
            ></div>
        ))}
    </div>
);

export const RegisterLoader = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                position: "fixed",
                zIndex: 10000000,
                top: 0,
                left: 0,
                backgroundColor: "#fff",
            }}
        >
            <div className={styles["register-browser-wrap"]}>
                <img
                    src="/image/BrowserLoaderMain.gif"
                    alt=""
                    style={{
                        width: "120px",
                        // position: "absolute",
                        // top: "50%",
                        // left: "50%",
                        // transform: "translate(-50%,-50%)",
                    }}
                />
                <h2 style={{ fontWeight: 400 }}>Preparing your workspace</h2>
                <p>
                    Please wait while your workspace is being configured, It'll be ready in a
                    moment.
                </p>
            </div>
        </div>
    );
};
