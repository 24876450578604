import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, Grid, GsFormProvider, LoginBanner, Snackbar } from "shared/Components";
import ReactLoading from "react-loading";

import styles from "../styles/Login.module.css";
import { LoginTextField } from "../components/loginField";
import { Helmet } from "react-helmet";
import { useLogin } from "../hooks/UseLogin";
import useAutoClose from "shared/Hooks/useAutoClose";
import { useGsForm } from "shared/Hooks";
import { ForgotPasswordSchema } from "../schema/AuthenticationSchema";

const ForgotPassword = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { isLoading, message, handleForgotPassword, clearMessage } = useLogin();

    useAutoClose(message, clearMessage);

    const methods = useGsForm({
        resolver: ForgotPasswordSchema,
        defaultValues: {
            email_id: state?.email_id || "",
        },
    });
    const { handleSubmit } = methods;

    return (
        <>
            <Helmet>
                <title>Userlove | Forgot Password</title>
            </Helmet>
            <Grid container className={styles["gs-login-page"]} spacing={5}>
                <Grid
                    item
                    // xs={6}
                    height={"100%"}
                    sx={{ display: "flex ", justifyContent: "flex-end " }}
                    className={styles["gs-login-banner-leftcontainer"]}
                >
                    <div className={styles["gs-login-fields"]}>
                        <div className={styles["gs-login-header"]}>
                            <img height={"55px"} alt="00" src="/image/loginLogo.png" />
                        </div>
                        <div className={styles["gs-forgot-password-from"]}>
                            <div className={styles["gs-forgot-password-header-text"]}>
                                <h1>Forgot password</h1>
                                <p
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        color: "var(--secondary-light-color)",
                                        marginBottom: "5px",
                                    }}
                                >
                                    If you have forgotten your password, please don't stress. Please
                                    enter your registered Email and click on reset password.
                                </p>
                            </div>
                            <Snackbar
                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                open={!!message.text}
                            >
                                <Alert
                                    icon={false}
                                    size="medium"
                                    type={message.type}
                                    sx={{ width: "auto", textAlign: "center" }}
                                >
                                    {message.text}
                                </Alert>
                            </Snackbar>
                            <GsFormProvider
                                methods={methods}
                                onSubmit={handleSubmit(handleForgotPassword)}
                                className={styles["gs-login-form-fields"]}
                            >
                                <LoginTextField
                                    name="email_id"
                                    sx={{ width: "100%" }}
                                    placeholder="you@company.com"
                                    label="Email Address"
                                    fullWidth
                                    require={true}
                                    autoFocus="autoFocus"
                                />

                                <Button
                                    className={"gs-primary-button"}
                                    type="submit"
                                    style={{ height: "50px" }}
                                >
                                    {isLoading ? (
                                        <ReactLoading
                                            className="gs-enable-disable-loader-bubble"
                                            type={"bubbles"}
                                            color={"var(--white-color)"}
                                        />
                                    ) : (
                                        "Send link"
                                    )}
                                </Button>
                                <div className={styles["gs-login-form-signup-button"]}>
                                    <p>Already have an account? </p>

                                    <Button
                                        onClick={() => {
                                            navigate("/login");
                                        }}
                                        className={styles["signup-signin-text"]}
                                    >
                                        Sign in
                                    </Button>
                                </div>
                            </GsFormProvider>
                        </div>

                        <div className={styles["gs-login-footer"]}>
                            <p>
                                © 2023 Userlove LLC.{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                    All rights reserved.
                                </span>
                            </p>
                        </div>
                    </div>
                </Grid>

                <Grid
                    item
                    xs={6}
                    height={"100%"}
                    className={styles["gs-login-banner-rightcontainer"]}
                >
                    <div className={styles["gs-login-banner"]}>
                        <LoginBanner />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default ForgotPassword;
