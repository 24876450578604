import React, { useMemo, useState } from "react";

import {
    DataGridPro,
    gridColumnLookupSelector,
    GridOverlay,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LinearProgress from "@mui/material/LinearProgress";

import { IconButton } from "@mui/material";

import styles from "./index.module.css";
import { useEffect } from "react";
import { useRef } from "react";
import { getSum, isFunc } from "shared/utils";
import Card from "./Components";
import { NoData } from "..";

const NoComponent = () => <></>;

const Loader = () => {
    return (
        <GridOverlay>
            <div style={{ position: "absolute", top: 0, width: "100%" }}>
                <LinearProgress className={styles.progress} />
            </div>
        </GridOverlay>
    );
};

// const TablePagination = () => {
// 	const apiRef = useGridApiContext();
// 	const page = useGridSelector(apiRef, gridPageSelector);
// 	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

// 	return (
// 		<Pagination
// 			count={pageCount}
// 			page={page + 1}
// 			onChange={(_event, value) => apiRef.current.setPage(value - 1)}
// 			classes={{
// 				root: styles.pagination,
// 			}}
// 		/>
// 	);
// };

const excludePaddingForm = ["__detail_panel_toggle__"];

const CustomToolBar = ({ data, columns, minWidth }) => {
    const apiRef = useGridApiContext();
    const ref = useRef();

    const columnLookups = useGridSelector(apiRef, gridColumnLookupSelector);

    useEffect(() => {
        const handleScroll = () => {
            const { left } = apiRef.current.getScrollPosition();
            ref.current.scrollTo(left, 0);
        };

        let scrollableDiv = null;

        const timeOutId = setTimeout(() => {
            scrollableDiv = apiRef.current.rootElementRef.current.querySelector(
                ".MuiDataGrid-virtualScroller",
            );

            if (scrollableDiv) {
                scrollableDiv.addEventListener("scroll", handleScroll);
            }
        }, 0);

        return () => {
            clearTimeout(timeOutId);
            if (scrollableDiv) scrollableDiv.removeEventListener("scroll", handleScroll);
        };
    }, [apiRef]);

    return (
        <div ref={ref} className={styles["toolbar-wrapper"]}>
            <div className={styles.toolbar} style={{ minWidth }}>
                {[...Object.values(columnLookups)].map((cell, index) => (
                    <div
                        key={index}
                        className={`${styles["toolbar-cell"]} ${
                            excludePaddingForm.includes(cell.field) ? "p-0" : ""
                        }`}
                        style={{
                            width: cell.computedWidth,
                        }}
                    >
                        {columns[cell.field] &&
                            isFunc(columns[cell.field]) &&
                            columns[cell.field](
                                cell,
                                data.map((item) => item[cell.field]),
                                data,
                            )}
                    </div>
                ))}
                <div style={{ clear: "both" }} />
            </div>
        </div>
    );
};

const ExpandAll = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const apiRef = useGridApiContext();
    const ids = apiRef.current.state.rows.ids;

    const handleClick = () => {
        if (isExpanded) {
            apiRef.current.setExpandedDetailPanels([]);
        } else {
            apiRef.current.setExpandedDetailPanels(ids);
        }
        setIsExpanded((prev) => !prev);
    };

    return (
        <>
            <IconButton onClick={handleClick} className={styles["expand-all-icon"]} size="small">
                {isExpanded ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
        </>
    );
};

// paginationProps ---
// paginationMode - server | client
// rowsPerPageOptions - Array<number>
// rowCount - number
// page - number
// onPageChange - func
// pageSize - number
// onPageSizeChange - func
// paginationProps ---

// sortingProps
// sortingMode - server | client
// sortModel - [{field: any, sort: asc | desc}]
// onSortModelChange - func

// expandRowProps
// getPanelHeight - func -> number
// renderContent - func - React Element
// ExpandIcon - Component
// CollapseIcon - Component

export const TableCard = ({
    columns: tableColumns,
    data,
    keyField = "id",
    loading,
    autoHeight = true,
    disablePagination = false,
    disableSorting = false,
    disableSearching = true,
    paginationProps = {},
    sortingProps = {},
    searchProps = {},
    expandableRows = false,
    checkboxSelection = true,
    hasCustomHeader = false,
    showNoData = true,
    expandRowProps: { getPanelHeight, renderContent, ExpandIcon, CollapseIcon } = {},
    onCellClick,
    onPageSizeChange,
    handleSearchText,
    resize = false,
    columnsHeaderClass = "",
    columnsHeader = "",
    primaryClassName,
    getSelectedRows,
    ...rest
}) => {
    const expandableColumns = useMemo(
        () => [
            ...(expandableRows
                ? [
                    {
                        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                        renderHeader: ExpandAll,
                    },
                ]
                : []),
            ...tableColumns,
        ],
        [tableColumns, expandableRows],
    );

    const columns = useMemo(
        () =>
            disableSorting
                ? expandableColumns.map(({ ...columnFields }) => ({
                    ...columnFields,
                    sortable: false,
                }))
                : expandableColumns.map(({ ...columnFields }) => ({
                    ...columnFields,
                })),
        [expandableColumns, disableSorting],
    );

    const disableAggregation = useMemo(
        () => !expandableColumns.some((props) => "renderAggregationCell" in props),
        [expandableColumns],
    );

    const aggregationColumns = useMemo(
        () =>
            Object.fromEntries(
                expandableColumns.map((col) => [col.field, col.renderAggregationCell]),
            ),
        [expandableColumns],
    );

    const minWidth = useMemo(
        () => getSum(columns.map((col) => +`${col.minWidth || col.width || 0}`.replace("px", ""))),
        [columns],
    );

    // const hasMoreThenOnePage = useMemo(() => rowCount > pageSize, [rowCount, pageSize]);

    // if (!loading && !data?.length) return <NoData />;
    if (showNoData && !hasCustomHeader && !loading && !data?.length) return <NoData />;

    return (
        <Card
            className={`${styles.card} ${autoHeight ? "" : styles["h-full"]}  ${primaryClassName}`}
        >
            <Card.Body withPadding={false}>
                {!disableSearching && (
                    <div className={styles["search-wrapper"]}>
                        <SearchIcon />
                        <input
                            placeholder="Search..."
                            {...searchProps}
                            onChange={handleSearchText}
                        />
                    </div>
                )}
                <DataGridPro
                    {...(autoHeight && { autoHeight: true })}
                    loading={loading}
                    checkboxSelection={checkboxSelection}
                    getSelectedRows={getSelectedRows}
                    columns={columns}
                    rows={data}
                    disableColumnMenu
                    disableSelectionOnClick
                    className={`${styles["table-wrapper"]} ${
                        autoHeight ? "" : styles["h-full"]
                    } ${primaryClassName}`}
                    classes={{
                        columnHeaders: styles["headers-wrapper"],
                        columnHeader: `${styles["table-header"]} ${columnsHeaderClass}`,
                        columnHeaderTitleContainer: `${styles["header-title-container"]} ${columnsHeader}`,
                        columnHeaderTitle: styles["header-title"],
                        cell: styles.cell,
                        row: styles.row,
                        footerContainer: styles["footer-wrapper"],
                        virtualScroller: styles.scroll,
                        detailPanel: styles["detail-panel"],
                    }}
                    {...(disablePagination && {
                        hideFooter: true,
                    })}
                    getRowHeight={() => "auto"}
                    getRowId={isFunc(keyField) ? keyField : (row) => row[keyField]}
                    components={{
                        // ColumnResizeIcon: NoComponent,
                        // Pagination: TablePagination,
                        LoadingOverlay: Loader,
                        ...(!disableAggregation && {
                            Toolbar: () => (
                                <CustomToolBar
                                    data={data}
                                    columns={aggregationColumns}
                                    minWidth={minWidth}
                                />
                            ),
                        }),
                        DetailPanelExpandIcon: ExpandIcon ?? KeyboardArrowRightIcon,
                        DetailPanelCollapseIcon: CollapseIcon ?? KeyboardArrowDownIcon,
                        ...(!resize
                            ? {
                                ColumnResizeIcon: NoComponent,
                            }
                            : {}),
                    }}
                    {...(!disablePagination && {
                        paginationMode: paginationProps.paginationMode ?? "server",
                        pagination: true,
                        rowsPerPageOptions: paginationProps.rowsPerPageOptions ?? [5, 10, 15, 20],
                        pageSize: paginationProps.pageSize ?? 10,
                        onPageSizeChange,
                        ...paginationProps,
                    })}
                    {...(!disableSorting && {
                        sortingMode: sortingProps.sortingMode ?? "server",
                        ...sortingProps,
                    })}
                    {...(expandableRows && {
                        getDetailPanelHeight: getPanelHeight,
                        getDetailPanelContent: renderContent,
                    })}
                    onCellClick={onCellClick}
                    {...rest}
                />
            </Card.Body>
        </Card>
    );
};

// export default TableCard;
