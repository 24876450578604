import React from "react";
import { MuiCard, CardContent } from "..";
import styles from "./Card.module.css";

const Content = ({ children, className, ...rest }) => (
    <CardContent className={`${styles["gs-cards-content"]} ${className || ""}`} {...rest}>
        {children}
    </CardContent>
);
export const Card = (props) => {
    const { children, ...rest } = props;
    return (
        <MuiCard classes={{ root: styles["gs-cards"] }} {...rest}>
            {children}
        </MuiCard>
    );
};

Card.Content = Content;
export default Card;
