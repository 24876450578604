export class Observable {
    constructor() {
        this.observers = [];
    }

    subscribe(fn) {
        this.observers.push(fn);
        return this.unsubscribe.bind(this, fn);
    }

    unsubscribe(fn) {
        this.observers = this.observers.filter((subscriber) => subscriber !== fn);
    }

    notify(data) {
        this.observers.forEach((subscriber) => subscriber(data));
    }
}
