import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";

const VALUE_FROM_BASED_ON_SCHEMA_DATATYPE = {
    string: "",
    array: [],
    any: "",
};

const getDefaultValuesFromResolver = (resolver) =>
    resolver.$_terms.keys.map((keyObj) => {
        return { [keyObj.key]: VALUE_FROM_BASED_ON_SCHEMA_DATATYPE[keyObj?.schema?.type] ?? "" };
    });

export const useGsForm = (options) => {
    const { resolver = (data) => ({ values: data }) } = options;
    const methods = useForm({
        ...options,
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: options.defaultValues ?? getDefaultValuesFromResolver(resolver),
        criteriaMode: "all",
        shouldFocusError: true,
        resolver: joiResolver(resolver),
    });
    return methods;
};
