import React from "react";
import styles from "./Drawer.module.css";
import {
    MUIDialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "..";
import CloseIcon from "@mui/icons-material/Close";

const Title = ({ children, onClose, className, ...rest }) => {
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...rest}>
            {children}
            {onClose ? (
                <Button
                    aria-label="close"
                    onClick={onClose}
                    className={`${styles["gs-modal-close-botton"]} ${className || ""}`}
                >
                    <CloseIcon />
                </Button>
            ) : null}
        </DialogTitle>
    );
};

const Content = ({ children, ...rest }) => {
    return (
        <DialogContent classes={{ root: styles["gs-modal-content"] }} {...rest}>
            {children}
        </DialogContent>
    );
};

const ContentText = ({ children, className, ...rest }) => {
    return (
        <DialogContentText
            className={className}
            //classes={{ root: styles["gs-modal-content"] }}
            {...rest}
        >
            {children}
        </DialogContentText>
    );
};

const Actions = ({ children, className, ...rest }) => {
    return (
        <DialogActions
            className={className}
            classes={{ root: styles["gs-modal-action-button"] }}
            {...rest}
        >
            {children}
        </DialogActions>
    );
};

export const Dialog = ({ open, children, onClose, ...rest }) => {
    return (
        <MUIDialog
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { maxWidth: "500px " } }}
            fullWidth
            classes={{ root: styles["gs-modal"], paper: styles["gs-modal-paper"] }}
            {...rest}
        >
            {children}
        </MUIDialog>
    );
};

Dialog.Title = Title;
Dialog.Content = Content;
Dialog.ContentText = ContentText;
Dialog.Actions = Actions;
