/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import styles from "./Navbar.module.css";
import { Breadcrumbs, Typography, SearchInput } from "shared/Components";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { BreadCrumbData } from "CommonData/BreadCrumbData";
import { ProfilePanel } from "./ProfilePanel";
import { ProductContext } from "App";
import _ from "lodash";
import { useNavbar } from "shared/Hooks";

export const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let pathName = location.pathname.split("/").filter((x) => x);
    if (
        pathName.includes("survey") ||
        pathName.includes("checklist") ||
        (pathName.includes("product-tour") &&
            (pathName.includes("create") || pathName.includes("update")))
    ) {
        pathName = pathName.filter((_item, index) => index !== 2 && index !== 3);
    } else if (pathName.includes("role-permission") && pathName.includes("edit")) {
        pathName = pathName.filter((item, i) => i !== 3);
    } else if (pathName.includes("applications")) {
        pathName = pathName.filter((item, index) => {
            return index !== 2 && index !== 3 && index !== 4 && index !== 5;
        });
    }

    const { setTheme, theme, setSearchText, searchText } = useContext(ProductContext);
    const {
        updateTheme,
        anchorEl,
        setAnchorEl,
        handleClick,
        handleClose,
        buttonObj,
        getAccount,
        rows,
        handleSwitchAccount,
        handleLogoutSubmit,
        roles,
        handleRoleChange,
        // getAccountByTokenAccountId,
        getAccountDetail,
    } = useNavbar();
    const handleTheme = () => {
        if (theme === "dark") {
            setTheme("light");
            updateTheme({
                variables: {
                    theme: "light",
                },
            });
        } else {
            setTheme("dark");
            updateTheme({
                variables: {
                    theme: "dark",
                },
            });
        }
    };
    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const debouncedSearch = _.debounce(handleSearch, 1000);

    return (
        <>
            <div className={styles["gs-navbar"]}>
                <div className={styles["gs-navbar-left-wrapper"]}>
                    <div
                        className={styles["gs-navbar-img-wrapper"]}
                        onClick={() => {
                            navigate("/dashboard");
                        }}
                    >
                        <img
                            className={styles["gs-navbar-logo"]}
                            src="/image/svg/IconLogo.svg"
                            alt="userloveiconlogo"
                            width={32}
                        />
                        <img
                            className={styles["gs-navbar-logo-text"]}
                            src="/image/svg/UserloveTextLogo.svg"
                            alt="userloveiconlogo"
                            width={120}
                        />
                    </div>
                    <Breadcrumbs
                        className="gs-breadcrumbs gs-navbar-breadcrumbs"
                        aria-label="breadcrumb"
                        separator={"/"}
                    >
                        <NavLink to={"/"}>home</NavLink>
                        {pathName.map((value, index) => {
                            const last = index === pathName.length - 1;
                            return last ? (
                                <Typography key={index} className={styles["gs-navbar-typo"]}>
                                    {value.replaceAll("-", " ")}
                                </Typography>
                            ) : (
                                <NavLink
                                    key={index}
                                    to={
                                        value.toLowerCase() === "home"
                                            ? BreadCrumbData[`/${value.toLowerCase()}/dashboard`]
                                            : BreadCrumbData[`/${value.toLowerCase()}`]
                                    }
                                >
                                    {value.replaceAll("-", " ")}
                                </NavLink>
                            );
                        })}
                    </Breadcrumbs>
                </div>
                <div className={styles["gs-navbar-rigth-wrapper"]}>
                    <SearchInput
                        searchIconSize={20}
                        size={"small"}
                        className={styles["gs-navbar-search"]}
                        onSearch={(e) => debouncedSearch(e)}
                        // InputProps={{
                        //   endAdornment: (
                        //     <IconButton
                        //       // onClick={onClear}
                        //       className={styles["gs-navbar-close-icons"]}
                        //       disableFocusRipple
                        //     >
                        //       <CancelIcon />
                        //     </IconButton>
                        //   ),
                        // }}
                    />
                    {/* <IconButton
            className={styles["gs-theme-button"]}
            // onClick={handleTheme}
          >
            <NightModeIcon />
          </IconButton> */}
                    <ProfilePanel
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleClick={handleClick}
                        handleClose={handleClose}
                        buttonObj={buttonObj}
                        getAccount={getAccount}
                        getAccountDetail={getAccountDetail}
                        rows={rows}
                        handleLogoutSubmit={handleLogoutSubmit}
                        roles={roles}
                        handleRoleChange={handleRoleChange}
                        handleSwitchAccount={handleSwitchAccount}
                        // getAccountByTokenAccountId={getAccountByTokenAccountId}
                    />
                </div>
            </div>
        </>
    );
};
