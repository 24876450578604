import { useState } from "react";

export const useProduct = () => {
    const [openExtendTrial, setOpenExtendTrial] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showSidebar, setShowSidebar] = useState(true);
    const [notification, setNotification] = useState({
        visible: false,
        message: "",
        type: "success",
    });

    const [profileData, setProfileData] = useState({});
    const [noDataOnError, setNoDataOnError] = useState(false);

    const [theme, setTheme] = useState(
        localStorage.getItem("theme") ? localStorage.getItem("theme") : "light",
    );

    //user dashboard
    const [addedConditions, setAddedConditions] = useState([]);
    const [segmentUsers, setSegmentUsers] = useState({
        data: [],
        rowsCount: [],
    });
    const [permissions, setPermissions] = useState([]);
    const [permissionLoading, setPermissionLoading] = useState(false);
    const [selectedSegment, setSelectedSegment] = useState({ segment_id: "all" });
    const [load, setLoad] = useState(false);
    const [columns, setColumns] = useState([
        "user",
        "country",
        "last_seen_at",
        "ip_address",
        "device_type",
        "session_state",
    ]);
    const themeBody = document.body;

    if (theme === "dark") {
        themeBody.setAttribute("dark-theme", "dark");
    } else if (theme === "light") {
        themeBody.setAttribute("dark-theme", "light");
    }

    const value = {
        searchText,
        setSearchText,
        showSidebar,
        setShowSidebar,
        notification,
        setNotification,
        profileData,
        setProfileData,
        setTheme,
        theme,
        addedConditions,
        setAddedConditions,
        segmentUsers,
        setSegmentUsers,
        setPermissions,
        permissions,
        setPermissionLoading,
        permissionLoading,
        selectedSegment,
        setSelectedSegment,
        noDataOnError,
        setNoDataOnError,
        columns,
        setColumns,
        load,
        setLoad,
        openExtendTrial,
        setOpenExtendTrial,
    };
    return { value };
};
