import { createUploadLink } from "apollo-upload-client";
import { ApolloClient, ApolloLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getCookie, setCookie } from "shared/Assets/JS/Cookies";
import axios from "axios";
import { Observable } from "apollo-link";
import { authObserver } from "modules/Authentication/hooks/UseLogin";
let urlWithSubdomainAdmin = `${process.env.REACT_APP_GRAPHQL_SERVER}/graphql`.split("//");

let urlWithSubdomainOnboarding = `${process.env.REACT_APP_ONBOARDING_SERVER}/graphql`.split("//");

const link = createUploadLink({
    // uri: `${urlWithSubdomainAdmin[0]}//${getCookie("sub_domain")}.${urlWithSubdomainAdmin[1]}`,
    uri: `${urlWithSubdomainAdmin[0]}//${urlWithSubdomainAdmin[1]}`,
});

const onboardingLink = createUploadLink({
    // uri: `${urlWithSubdomainOnboarding[0]}//${getCookie("sub_domain")}.${
    //     urlWithSubdomainOnboarding[1]
    // }`,
    uri: `${urlWithSubdomainOnboarding[0]}//${urlWithSubdomainOnboarding[1]}`,
});

const authLink = setContext((_, { headers }) => {
    // const token = getCookie("access_token");
    const token = getCookie("admin_access_token");

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            "sub-domain": getCookie("admin_sub_domain"),
        },
    };
});

const promiseToObservable = (promise) =>
    new Observable((subscriber) => {
        promise.then(
            (value) => {
                if (subscriber.closed) return;
                subscriber.next(value);
                subscriber.complete();
            },
            (err) => {
                authObserver.notify();
                return subscriber.error(err);
            },
        );
        return subscriber; // this line can removed, as per next comment
    });

const refreshToken = () => {
    // const token = getCookie("refresh_token");
    const token = getCookie("admin_refresh_token");
    return axios.post(
        `${process.env.REACT_APP_LOGIN_SERVER}/user/refresh-token`,
        {
            grant_type: "refresh_token",
        },
        {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
                "sub-domain": getCookie("admin_sub_domain"),
            },
        },
    );
};

const errorLink = onError(
    ({
        forward,
        operation,
        networkError: {
            result: { error, statusCode },
        },
    }) => {
        if (statusCode === 401 && error[0] === "Token Expired") {
            // FETCH NEW TOKEN
            return promiseToObservable(refreshToken()).flatMap((value) => {
                const token = value?.data?.data?.access_token;
                setCookie("admin_access_token", token, 10000);
                setCookie("admin_refresh_token", value?.data?.data?.refresh_token, 10000);
                setCookie("admin_sub_domain", value?.data?.data?.sub_domain, 10000);
                operation.setContext(() => ({
                    headers: {
                        authorization: token ? `Bearer ${token}` : "",
                        "sub-domain": getCookie("admin_sub_domain"),
                    },
                }));
                return forward(operation);
            });
        } else if (statusCode === 401 && error[0] === "Invalid Token") {
            authObserver.notify();
            // clearCookie();
            // window.location.replace(`${process.env.REACT_APP_SITE_URL}/login`);
            return;
        } else if (statusCode === 500 && error[0] === "Token Not Found!") {
            authObserver.notify();
            // clearCookie();
            // window.location.replace(`${process.env.REACT_APP_SITE_URL}/login`);
            return;
        }
    },
);

const httpLink = ApolloLink.split(
    (operation) => operation.getContext().clientName === "onboarding",
    authLink.concat(onboardingLink),
    authLink.concat(link),
);

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, httpLink]),
});
