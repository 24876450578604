import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import HttpApi from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// import translationEN from "shared/Assets/JS/en/translation";
// import translationFR from "shared/Assets/JS/fr/translation.json";
// import translationES from "shared/Assets/JS/spanish/translation.json";

// const resources = {
//   en: {
//     translation: translationEN,
//   },
//   fr: {
//     translation: translationFR,
//   },
//   es: {
//     translation: translationES,
//   },
// };

// i18n
//   .use(HttpApi)
//   // .use(LanguageDetector)
//   // .use(Fetch)
//   // .use(ChainedBackend)
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     saveMissing: false, // send not translated keys to endpoint
//     nonExplicitWhitelist: true,
//     // detection: {
//     //   order: ["path", "cookie", "htmlTag"],
//     //   caches: ["cookie"],
//     // },
//     // resources,
//     // resources: {

//     ns: ["translation"],
//     fallbackLng: "en",
//     backend: {
//       // for all available options read the backend's repository readme file
//       loadPath: `http://userlove.api:4000/lang/{{lng}}/translation.json`,

//       // keySeparator: false, // we do not use keys in form messages.welcome
//     },
//     // debug: true,
//     interpolation: {
//       escapeValue: false, // react already safes 192.168.0.227from xss
//     },
//   });

export default i18n;
