import { gql } from "@apollo/client";

export const GET_ALL_USERS_PROFILE_DATA = gql`
    query {
        getDataOfLoggedInUser {
            status
            statusCode
            message
            error
            data {
                user_id
                first_name
                last_name
                country_id
                locale_id
                trial_expired
                geo_zone_id
                email_id
                profile_picture
                phone_code
                account_id
                account_name
                role_id
                phone_no
                country
                phone_country_id
                timezone_id
                role_name
                phone_code
                phone_no_verified
            }
        }
    }
`;

export const GET_TIME_ZONE = gql`
    query {
        getTimeZone {
            status
            statusCode
            message
            error
            data {
                timezone_id
                display_name
            }
        }
    }
`;

export const GET_ALL_USER_PROFILE_ID = gql`
    query {
        getUserByLoginSession {
            status
            statusCode
            message
            error
            data {
                user_id
                account_id
                email_id
                is_navigate
                trial_expired
                product_tour
                checklist
                nps
                first_name
                last_name
                profile_picture
                custom_logo
                square_logo
                phone_no
                geo_zone_id
                country_id
                title
                description
                general_email_id
                general_phone
                currency {
                    currency_id
                    code
                    symbol
                    currency
                }
                locale_id
                role_id
                account_name
                user_account {
                    account {
                        account_id
                        email_id
                        account_name
                        logo
                    }
                }
            }
        }
    }
`;
