import React, { createContext, useContext, useState } from "react";
import { ListSubheader, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import styles from "./Dropdown.module.css";
import { forwardRef } from "react";
import { Box } from "shared/Components";
import { SearchIcon } from "shared/Assets/Icons";
import { useRef } from "react";
import { isFunc } from "shared/utils";

const ctx = createContext();

const DropdownItem = ({ children, ...rest }) => {
    const { size } = useContext(ctx);

    return (
        <MenuItem className={`${size === "small" ? styles["small-menu-item"] : ""}`} {...rest}>
            {children}
        </MenuItem>
    );
};

export const Dropdown = forwardRef(
    (
        {
            // required
            children,
            // optional
            size = "medium", // small, medium
            fullWidth = true,
            outlined = true,
            className = "",
            border = "",
            height = "",
            backgroundColor: bgColor = "var(--white-color)",
            inValid = false,
            valid = false,
            label,
            helperText,
            dropdownClassName = "",
            renderValue,
            labelClassName,
            selectClassName,
            require = false,
            menuAutoFocus = false,
            popOverClassName,
            IconComponent = KeyboardArrowDownIcon,
            menuprops = {},
            allValue = "all",
            ...rest
        },
        ref,
    ) => {
        const backgroundColor = outlined ? bgColor : "var(--gray-bk-color)";

        const inValidClassName = inValid ? styles.error : "";
        const validClassName = valid ? styles.success : "";

        return (
            <Box
                className={`${styles.wrapper} ${inValidClassName} ${validClassName} ${className} ${styles["gs-simple-dropdown"]}`}
            >
                {label && (
                    <label className={`${styles.label} ${labelClassName}`}>
                        {label}
                        {require && <span style={{ color: "var(--danger-color)" }}>{"*"}</span>}
                    </label>
                )}
                <ctx.Provider value={{ size }}>
                    <Select
                        ref={ref}
                        variant="outlined"
                        size={size}
                        fullWidth={fullWidth}
                        style={{
                            backgroundColor,
                            border,
                            height,
                        }}
                        className={`${styles["gs-dropdown-select"]} ${dropdownClassName} ${selectClassName} `}
                        classes={{
                            select: styles.select,
                            icon: styles.icon,
                        }}
                        IconComponent={IconComponent}
                        MenuProps={{
                            className: `${styles["gs-dropdown-popover-menu"]} ${popOverClassName}`,
                            autoFocus: menuAutoFocus,
                            ...menuprops,
                        }}
                        renderValue={renderValue}
                        {...rest}
                    >
                        {rest?.allOption ? (
                            <Dropdown.Item value={allValue}>All</Dropdown.Item>
                        ) : null}
                        {children}
                    </Select>
                </ctx.Provider>
                {!!helperText && (
                    <div className={styles["helper-text"]}>
                        {/* <InfoOutlinedIcon /> */}
                        <p>{helperText}</p>
                    </div>
                )}
            </Box>
        );
    },
);

export const SearchableDropdown = forwardRef((props, ref) => {
    const [search, setSearch] = useState("");

    if (!isFunc(props.children)) throw Error("Children has to be a function.");

    const handleChange = (e) => setSearch(e.target.value);

    const searchInputRef = useRef(null);

    return (
        <Dropdown
            onOpen={() => {
                setTimeout(() => {
                    searchInputRef?.current?.focus();
                }, 500);
            }}
            onClose={() => {
                setTimeout(() => {
                    setSearch("");
                }, 100);
            }}
            {...props}
            ref={ref}
        >
            <ListSubheader>
                <div className={styles["gs-dropdown-search-input"]}>
                    <SearchIcon width={"14px"} />
                    <input
                        ref={searchInputRef}
                        onChange={handleChange}
                        value={search}
                        placeholder={props.searchInputPlaceholder}
                        onKeyDown={(e) => e.stopPropagation()}
                    />
                </div>
            </ListSubheader>
            {props?.allOption ? <Dropdown.Item value="All">All</Dropdown.Item> : null}
            {props.children({ search })}
        </Dropdown>
    );
});

Dropdown.Item = DropdownItem;
