export const Routes = {
    Auth: {
        Login: "/login",
        Registration: "/register",
        OtpLogin: "/otp-login",
        ForgotPassword: "/forgot-password",
        TwofactorAuth: "/two-factor-auth",
        ResetPassword: "/reset-password",
        BookDemoForm: "/book-demo",
        TermsService: "/terms-of-service",
        PrivacyPolicy: "/privacy-policy",
    },
    Admin: {
        Dashboard: "/admin/dashboard",
        Settings: {
            LocalVariables: "/settings/local-variables",
        },
    },
    User: {
        Dashboard: "/dashboard",
        NPS: "/nps",
        Checklist: "/checklist",
        ProductTour: "/product-tour",
        Hotspots: "/hotspots",
        Users: "/users",
        Report: "/events",
        Settings: {
            Profile: {
                GeneralSettings: "/settings/general",
                BusinessSettings: "/settings/business",
                EmailTemplates: "/settings/emailtemplates",
            },
            Product: {
                Payments: "/settings/payments",
                // Goals: "/settings/goals",
                Themes: "/settings/themes",
                Goal: "/settings/goal",
            },
            Organization: {
                Variables: "/settings/variables",
                DemoRequest: "/settings/demo-request",
                // Team: "/settings/team",
                // Notification: "/settings/notification",
                // Billing: "/settings/billing",
                // Usage: "/settings/usage",
            },
        },
        Help: "/help",
    },
};
