import { Joi } from "shared/utils/joi";

export const LoginSchema = Joi.object({
    email_id: Joi.string()
        .email({ tlds: { allow: ["com", "net"] } })
        .required()
        .label("Email"),
    password: Joi.string()
        .required()
        .min(8)
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).+$/)
        .message({
            "string.pattern.base":
                "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character",
        })
        .label("Password"),
});

export const RegistrationSchema = Joi.object({
    user_name: Joi.string().required().label("Name"),
    email_id: Joi.string()
        .email({ tlds: { allow: ["com", "net"] } })
        .required()
        .label("Email"),
    password: Joi.string()
        .required()
        .min(8)
        .messages({
            "string.base":
                "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character ",
        })
        .label("Password"),
    company_name: Joi.string().required().label("Company name"),
    profile_picture: Joi.string().required().label("Picture"),
});

export const ForgotPasswordSchema = Joi.object({
    email_id: Joi.string()
        .email({ tlds: { allow: ["com", "net"] } })
        .required()
        .label("Email"),
});

export const SetPasswordSchema = Joi.object({
    first_name: Joi.string().required().label("First name"),
    last_name: Joi.string().required().label("Last name"),
    email_id: Joi.string()
        .email({ tlds: { allow: ["com", "net"] } })
        .required()
        .label("Email"),
    password: Joi.string()
        .required()
        .min(8)
        .messages({
            "string.base":
                "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character ",
        })
        .label("Password"),
    confirm_password: Joi.string()
        .required()
        .min(8)
        .messages({
            "string.base":
                "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character ",
            "any.only": "Passwords do not match",
        })
        .label("Confirmed password")
        .equal(Joi.ref("password")),
});

export const ResetPasswordSchema = Joi.object({
    password: Joi.string()
        .required()
        .min(8)
        .messages({
            "string.base":
                "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character ",
        })
        .label("Password"),
    confirm_password: Joi.string()
        .required()
        .min(8)
        .messages({
            "string.base":
                "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character ",
            "any.only": "Passwords do not match",
        })
        .label("Confirmed password")
        .equal(Joi.ref("password")),
});
