import { ProductContext } from "App";
import React, { Suspense } from "react";
import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ReactLoading from "react-loading";
import routes from "./routes.config";
import { useLogin } from "modules/Authentication/hooks/UseLogin";
import { RegisterLoader, Toast } from "shared/Components";

//---------------------------- ROUTE FUNCTIONS ------------------------------------

const Common = (route) => (
    <Suspense
        fallback={
            <ReactLoading
                className="gs-enable-disable-loader-bubble"
                type={"circles"}
                color={"green"}
            />
        }
    >
        <route.component />
    </Suspense>
);

// useEffect(() => {
//     // if(window.location.hostname.includes())
// },[]);

const Public = (route) => {
    const { isAuth } = useLogin();

    if (isAuth && !window.location.pathname.includes("invitation-link"))
        return <Navigate to="/" replace />;

    // Logic for public routes

    // const { user } = useAuth();

    // const redirectTo = "/";

    // if (!!user) return <Navigate to={redirectTo} replace />;

    return (
        <Suspense
            fallback={
                <ReactLoading
                    className="gs-enable-disable-loader-bubble"
                    type={"circles"}
                    color={"var(--primary-color)"}
                />
            }
        >
            <route.component />
        </Suspense>
    );
};

const Private = (route) => {
    const { isAuth } = useLogin();

    if (!isAuth) return <Navigate to="/login" replace />;

    // Logic for Private routes

    const { component: Component, defaultProps } = route;

    //   const currentUserRole = user.role;

    //   if (!!permissions?.length && !permissions.includes(currentUserRole))
    //     return <Navigate to={"/unauthorized"} replace />;

    return (
        <Suspense
            fallback={
                <ReactLoading
                    className="gs-enable-disable-loader-bubble"
                    type={"circles"}
                    color={"var(--primary-color)"}
                />
            }
        >
            <Component {...defaultProps} />
        </Suspense>
    );
};

const createNestedRoutes = (routes, RouteType) => {
    return routes.map((route, i) => {
        if (!route.break && !route.label && !route.external) {
            if (!route.component) {
                throw new Error("Component must be required....");
            }
            if (route.children) {
                return (
                    <Route
                        path={route.path}
                        key={i}
                        element={<RouteType component={route.component} />}
                    >
                        {createNestedRoutes(route.children, RouteType)}
                    </Route>
                );
            } else {
                return (
                    <Route
                        key={i}
                        index={route.index}
                        path={route.path}
                        element={
                            <RouteType
                                component={route.component}
                                defaultProps={route.defaultProps}
                            />
                        }
                    />
                );
            }
        }
    });
};

const Product = () => {
    const { isAppLoading, registerLoading } = useLogin();
    const { notification, setNotification } = useContext(ProductContext);
    const { public: publicRoutes, common, private: privateRoutes } = routes;

    if (isAppLoading)
        return (
            <>
                <div
                    id="BrowserLoader"
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "fixed",
                        zIndex: 10000000,
                        top: 0,
                        left: 0,
                        backgroundColor: "#fff",
                    }}
                >
                    <img
                        src="/image/BrowserLoaderMain.gif"
                        alt=""
                        style={{
                            width: "120px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                        }}
                    />
                </div>
            </>
        );

    if (registerLoading) return <RegisterLoader />;

    return (
        <>
            <div className="gs-main">
                <Routes>
                    {createNestedRoutes(privateRoutes, Private)}
                    {createNestedRoutes(common, Common)}
                    {createNestedRoutes(publicRoutes, Public)}
                </Routes>
                {notification.visible && (
                    <Toast
                        type={notification.type}
                        open={notification.visible}
                        onClose={() => {
                            setNotification({
                                visible: false,
                                message: "",
                                type: "success",
                            });
                        }}
                    >
                        {notification.message}
                    </Toast>
                )}
            </div>
        </>
    );
};

export default Product;
