import * as React from "react";

import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import styles from "./RangeSlider.module.css";

const PrettoSlider = styled(Slider)({
    color: "#00ABFF",
    height: 4,
    "& .MuiSlider-track": {
        border: "none",
    },
    "& .MuiSlider-thumb": {
        height: 12,
        width: 12,
        backgroundColor: "#fff",
        border: "3px solid currentColor",
        boxShadow: "0px 2px 6px 1px rgb(0 0 0 / 20%)",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "0px 2px 6px 1px rgb(0 0 0 / 20%)",
        },
        "&:before": {
            display: "none",
        },
    },
    "& .MuiSlider-valueLabel": {
        lineHeight: 1.2,
        fontSize: 12,
        background: "unset",
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: "50% 50% 50% 0",
        backgroundColor: "#000000",
        transformOrigin: "bottom left",
        transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
        "&:before": { display: "none" },
        "&.MuiSlider-valueLabelOpen": {
            transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
        },
        "& > *": {
            transform: "rotate(45deg)",
        },
    },
});
export const RangeSlider = ({ onChange, defaultValue = 20, value, min = 0, max = 100 }) => {
    return (
        <PrettoSlider
            className={styles["gs-slider"]}
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={defaultValue}
            onChange={onChange}
            value={value}
            min={min}
            max={max}
        />
    );
};
