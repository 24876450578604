import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./PhoneNumberInput.module.css";

const PhoneNumberInput = ({
    onlyCountries,
    fieldLabel,
    onChange = null,
    verifyButtonLabel,
    onClickVerifyButton,
    className,
    disabled,
    value,
    autoFormat = false,
    isValid,
    name,
    ...rest
}) => {
    const { control, setValue } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div>
                    <label htmlFor="phonenumber" className={styles["gs-phone-input-label"]}>
                        {fieldLabel}
                    </label>
                    <div className={styles["gs-country-code-phone-input-wrapper"]}>
                        <PhoneInput
                            className={
                                disabled
                                    ? `${styles["gs-country-code-phone-input"]} ${
                                        styles["phone-number-disabled"]
                                    } ${className || ""}`
                                    : `${styles["gs-country-code-phone-input"]}  ${className || ""}`
                            }
                            disabled={disabled}
                            name={"phone"}
                            country={"in"}
                            {...field}
                            onlyCountries={onlyCountries}
                            enableSearch={true}
                            autoFormat={autoFormat}
                            value={value}
                            inputProps={{
                                name: "phone",
                                required: true,
                                autoFormat: true,
                            }}
                            inputStyle={{
                                width: "100%",
                                border: "1px solid transparent",
                                height: "42px",
                                color: disabled
                                    ? "rgba(0, 0, 0, 0.38)"
                                    : "var(--secondary-light-color)",
                                cursor: "auto",
                            }}
                            onChange={(value, country) =>
                                onChange
                                    ? onChange(value, country)
                                    : setValue(name, value, { shouldDirty: true })
                            }
                            disableSearchIcon={true}
                            isValid={isValid}
                            {...rest}
                        />
                        <button
                            type="button"
                            className={styles["gs-country-code-phone-input-status"]}
                            onClick={onClickVerifyButton}
                        >
                            {verifyButtonLabel}
                        </button>
                    </div>
                </div>
            )}
        />
    );
};

export { PhoneNumberInput };
