import React from "react";
import { alertTypes, Snackbar, Alert, Slide } from "..";
import styles from "./Toast.module.css";

const transitionComponents = {
    "top-left": (props) => <Slide {...props} direction="right" />,
    "bottom-left": (props) => <Slide {...props} direction="right" />,
    "top-right": (props) => <Slide {...props} direction="left" />,
    "bottom-right": (props) => <Slide {...props} direction="left" />,
    "top-center": (props) => <Slide {...props} direction="down" />,
    "bottom-center": (props) => <Slide {...props} direction="up" />,
};

export const Toast = ({
    type = alertTypes.SUCCESS,
    onClose,
    open,
    autoHideDuration = 6000,
    // top, bottom
    vertical = "bottom",
    // left, center, right
    horizontal = "right",
    children,
}) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={{ vertical, horizontal }}
            TransitionComponent={transitionComponents[`${vertical}-${horizontal}`]}
            className={styles["gs-toast"]}
        >
            <Alert type={type} onClose={onClose} toast>
                {children}
            </Alert>
        </Snackbar>
    );
};
