import { Chip, Grid } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./MultiInputEmail.module.css";
import { Joi } from "shared/utils/joi";
import { ALLOWED_TLDS_EMAIL } from "CommonData/config";

export const MultiInputEmail = ({ name, label = null }) => {
    const { control, setValue, watch, setError, clearErrors } = useFormContext();
    const handleDelete = (emailSelected) => {
        setValue(
            name,
            watch(name)?.filter((email) => email !== emailSelected),
            { shouldDirty: true },
        );
    };

    function handleInputChange(e) {
        const joiValidation = Joi.string().email({
            tlds: { allow: ALLOWED_TLDS_EMAIL },
        });
        const isEmail = joiValidation.validate(e.target.value);

        const validationCheck =
            e.key === " " && e.target.value.length && !isEmail.hasOwnProperty("error");

        if (validationCheck) {
            clearErrors(name);
            setValue(name, [...watch(name), e.target.value.replace(",", "")], {
                shouldDirty: true,
            });
            e.target.value = "";
        } else {
            setError(name, { type: "custom", message: "Invalid Email" });
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref: formInputRef, ...field }, fieldState: { error } }) => (
                <>
                    {label && (
                        <label className={styles["label"]}>
                            {label + " "}{" "}
                            {require && <span style={{ color: "var(--danger-color)" }}>{"*"}</span>}
                        </label>
                    )}
                    <Grid
                        container
                        columnGap={1}
                        rowGap={1}
                        alignItems="center"
                        flexDirection="row"
                        className={styles["gs-email-field-container"]}
                        style={{ borderColor: error && "var(--danger-color)" }}
                    >
                        {watch(name)?.length
                            ? watch(name)?.map((email) => (
                                <Grid item key={email}>
                                    <Chip label={email} onDelete={() => handleDelete(email)} />
                                </Grid>
                            ))
                            : null}
                        <Grid item flexGrow={1}>
                            <input
                                type="email"
                                placeholder={watch(name)?.length ? null : "Enter email id"}
                                name={name}
                                onBlur={field.onBlur}
                                onKeyUp={handleInputChange}
                                className={styles["gs-email-input"]}
                                ref={formInputRef}
                            />
                        </Grid>
                    </Grid>
                    {!!error && Array.isArray(error) ? (
                        <div className={styles["helper-text"]}>
                            {error?.map((err) => (
                                <p key={err}>{err?.message}</p>
                            ))}
                        </div>
                    ) : (
                        <div className={styles["helper-text"]}>
                            <p>{error?.message}</p>
                        </div>
                    )}
                </>
            )}
        />
    );
};

export default MultiInputEmail;
