import { useQuery } from "@apollo/client";
import React, { useContext, createContext } from "react";
import { GET_ALL_PAYMENTS } from "../graphql/payment";
import { TableData } from "shared/Components";
import { useState } from "react";
import CustomHeader from "shared/Components/CustomHeader/CustomHeader";
import useDateRange from "shared/Hooks/useDateRange";
import moment from "moment";

const ctx = createContext();

export const usePayment = () => useContext(ctx);

//?---------------------------------- CUSTOM ORGNIZATION CELL	---------------------------------*/
const CustomOrgnizationCell = ({ row }) => {
    return (
        <TableData
            showImage={true}
            srcType="avatar"
            url={row?.logo}
            primaryText={row?.account_name || null}
            secondaryText={row?.email_id || null}
        ></TableData>
    );
};

//?---------------------------------- CUSTOM COUNTRY CELL ---------------------------------*/
const CustomCountryCell = ({ row }) => {
    return (
        <TableData
            showImage={true}
            srcType="image"
            url={row?.country_flag ? row?.country_flag : ""}
            primaryText={row?.country || null}
            secondaryText={row?.state_name || null}
        ></TableData>
    );
};

//?---------------------------------- CUSTOM TRANSACTION ID CELL ---------------------------------*/
const CustomTransactionCell = ({ row }) => {
    return row?.transaction_id;
};

//?---------------------------------- CUSTOM AMOUNT CELL ---------------------------------*/
const CustomAmountCell = ({ row }) => {
    <TableData primaryText={row?.amount || null}></TableData>;
};

//?---------------------------------- CUSTOM PAYMENT GATEWAY CELL ---------------------------------*/
const CustomPaymentGatewayCell = ({ row }) => {
    <TableData primaryText={row?.payment_method || null}></TableData>;
};
export const PaymentProvider = ({ children }) => {
    //?---------------------------------- STATE INITIALIZATION	---------------------------------*/
    const [conditions, setConditions] = useState([
        {
            field: "account_name",
            search: "",
        },
        {
            field: "country",
            search: "",
        },
        {
            field: "transaction_id",
            search: "",
        },
        {
            field: "amount",
            search: "",
        },
        {
            field: "payment_with",
            search: "",
        },
    ]);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);

    /**
     * @param {number} newVal - Page number
     */
    const handlePageChange = (newVal) => {
        setPage(newVal);
    };

    /**
     * @param {number} newVal - Page number
     */
    const handlePageSizeChange = (newVal) => {
        setSizePerPage(newVal);
    };

    const rangePickerProps = useDateRange();

    //?---------------------------------- GET ALL PAYMENT  ---------------------------------*/
    const {
        loading: getPaymentLoading,
        data: { getTransactionHistory: { data: row = [], rowsCount: count = 0 } } = {
            getTransactionHistory: {},
        },
        refetch: refetchTransactionHistory,
    } = useQuery(GET_ALL_PAYMENTS, {
        variables: {
            filter: rangePickerProps?.selected?.id || "custom",
            startDate: moment.utc(rangePickerProps?.start)?.toISOString() || null,
            endDate: moment.utc(rangePickerProps?.end)?.toISOString() || null,
            conditions: conditions,
            skip: page * sizePerPage,
            take: sizePerPage,
        },
    });

    //?---------------------------------- COLUMN INTIALIZATION FOR TABLE ---------------------------------*/
    const columns = [
        {
            field: "orgnization",
            flex: 1,
            minWidth: 300,
            renderHeader: () => {
                return (
                    <CustomHeader
                        headerName={"Organization"}
                        conditions={conditions}
                        setConditions={setConditions}
                        fieldKey={"account_name"}
                        refetch={() => {
                            refetchTransactionHistory({
                                startDate:
                                    moment.utc(rangePickerProps?.start)?.toISOString() || null,
                                endDate: moment.utc(rangePickerProps?.end)?.toISOString() || null,
                                conditions: conditions,
                            });
                        }}
                    />
                );
            },
            renderCell: CustomOrgnizationCell,
        },
        {
            field: "country",
            flex: 1,
            minWidth: 180,
            renderHeader: () => {
                return (
                    <CustomHeader
                        headerName={"Country"}
                        conditions={conditions}
                        fieldKey={"country"}
                        setConditions={setConditions}
                        refetch={() =>
                            refetchTransactionHistory({
                                startDate:
                                    moment.utc(rangePickerProps?.start)?.toISOString() || null,
                                endDate: moment.utc(rangePickerProps?.end)?.toISOString() || null,
                                conditions: conditions,
                            })
                        }
                    />
                );
            },
            renderCell: CustomCountryCell,
        },
        {
            field: "transaction_id",
            flex: 1,
            minWidth: 300,
            renderHeader: () => {
                return (
                    <CustomHeader
                        headerName={"Transaction id"}
                        fieldKey={"transaction_id"}
                        conditions={conditions}
                        setConditions={setConditions}
                        refetch={() =>
                            refetchTransactionHistory({
                                startDate:
                                    moment.utc(rangePickerProps?.start)?.toISOString() || null,
                                endDate: moment.utc(rangePickerProps?.end)?.toISOString() || null,
                                conditions: conditions,
                            })
                        }
                    />
                );
            },
            renderCell: CustomTransactionCell,
        },
        {
            field: "amount",
            flex: 1,
            minWidth: 160,
            renderHeader: () => {
                return (
                    <CustomHeader
                        headerName={"Amount"}
                        fieldKey={"amount"}
                        conditions={conditions}
                        setConditions={setConditions}
                        refetch={() =>
                            refetchTransactionHistory({
                                startDate:
                                    moment.utc(rangePickerProps?.start)?.toISOString() || null,
                                endDate: moment.utc(rangePickerProps?.end)?.toISOString() || null,
                                conditions: conditions,
                            })
                        }
                    />
                );
            },
            renderCell: CustomAmountCell,
        },
        {
            field: "payment_method",
            flex: 1,
            minWidth: 160,
            renderHeader: () => {
                return (
                    <CustomHeader
                        headerName={"Gateway"}
                        conditions={conditions}
                        fieldKey={"payment_method"}
                        setConditions={setConditions}
                        refetch={() =>
                            refetchTransactionHistory({
                                startDate:
                                    moment.utc(rangePickerProps?.start)?.toISOString() || null,
                                endDate: moment.utc(rangePickerProps?.end)?.toISOString() || null,
                                conditions: conditions,
                            })
                        }
                        // handleSearch={(e) => handleSearch(e, "payment_with")}
                        isDropdownFilter={true}
                    />
                );
            },
            renderCell: CustomPaymentGatewayCell,
        },
    ];

    return (
        <ctx.Provider
            value={{
                columns,
                row,
                getPaymentLoading,
                ...rangePickerProps,
                setConditions,
                conditions,
                refetchTransactionHistory,
                handlePageChange,
                page,
                count,
                handlePageSizeChange,
                sizePerPage,
            }}
        >
            {children}
        </ctx.Provider>
    );
};
