import React, { forwardRef } from "react";
import { MUIAlert } from "..";

import styles from "./Alert.module.css";

export const alertTypes = {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
};

const sizeClasses = {
    small: styles.small,
    medium: styles.medium,
};

export const Alert = forwardRef(
    ({ children, type = alertTypes.SUCCESS, onClose, toast, size = "medium", ...rest }, ref) => {
        return (
            <MUIAlert
                ref={ref}
                classes={{
                    root: `${styles.alert} ${sizeClasses[size]} ${styles[type]} ${
                        toast ? styles.toast : ""
                    }`,
                    icon: styles.icon,
                    action: styles.action,
                    message: styles.message,
                }}
                severity={type}
                variant="standard"
                onClose={onClose}
                {...rest}
            >
                {children}
            </MUIAlert>
        );
    },
);
