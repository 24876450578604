import React from "react";
import styles from "./Footer.module.css";
export const Footer = () => {
    const date = new Date();
    return (
        <div className={styles["gs-footer"]}>
            <div>{date.getFullYear()} © Gainserv LLC.</div>
            <div>Version: 1.0.0</div>
        </div>
    );
};
