import React, { forwardRef, useId } from "react";

import { Box, ErrorMessage, MUITextField } from "..";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import styles from "./TextField.module.css";
import { Controller, useFormContext } from "react-hook-form";

const getDefaultInputProps = (id) => ({
    id,
});

const TextField = forwardRef(
    ({
        fullWidth = true,
        placeholder,
        inValid = false,
        valid = false,
        label,
        // helperText,
        type = "text",
        multiline = false,
        rows = 4,
        size = "medium",
        className = "",
        textFieldClassName = "",
        labelTextClassName = "",
        require = false,
        helperClassName = "",
        name,
        onChange,
        ...rest
    }) => {
        const id = useId();
        const { control } = useFormContext();
        const inValidClassName = (invalid) => (require && invalid ? styles.error : "");
        const validClassName = (invalid, isDirty) =>
            require && !invalid && isDirty ? styles.success : "";

        return (
            <Controller
                name={name}
                control={control}
                render={({
                    field: { ref: formInputRef, ...field },
                    fieldState: { invalid, isDirty, error, isTouched },
                }) => (
                    <Box
                        className={`${styles.wrapper} ${inValidClassName(
                            invalid,
                            isDirty,
                            error,
                            isTouched,
                        )} ${validClassName(invalid, isDirty, error, isTouched)} ${className}`}
                    >
                        {label && (
                            <label htmlFor={id} className={`${styles.label} ${labelTextClassName}`}>
                                {label}{" "}
                                {require && (
                                    <span style={{ color: "var(--danger-color)" }}>{" *"}</span>
                                )}
                            </label>
                        )}
                        <MUITextField
                            type={type}
                            className={`${styles["text-field"]} ${textFieldClassName}`}
                            name={name}
                            // inputRef={ref}
                            error={inValid}
                            fullWidth={fullWidth}
                            placeholder={placeholder}
                            size={size}
                            inputRef={formInputRef}
                            InputProps={{
                                ...getDefaultInputProps(id),
                                ...(valid && {
                                    endAdornment: (
                                        <CheckCircleOutlineOutlinedIcon
                                            className={styles["success-icon"]}
                                        />
                                    ),
                                }),
                            }}
                            {...(multiline && {
                                multiline: true,
                                rows,
                            })}
                            inputProps={{ ...rest }}
                            {...field}
                            onChange={(e) => (onChange ? onChange(e) : field.onChange(e))}
                            {...rest}
                        />
                        {!!error && (
                            <ErrorMessage
                                helperClassName={helperClassName}
                                message={error?.message}
                            />
                        )}
                    </Box>
                )}
            />
        );
    },
);

export { TextField };
