import React, { forwardRef, useId } from "react";

import { Box, MUITextField } from "../";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import styles from "./TextField.module.css";

const getDefaultInputProps = (id) => ({
    id,
});

const TextField = forwardRef(
    (
        {
            fullWidth = true,
            placeholder,
            inValid = false,
            valid = false,
            label,
            helperText,
            type = "text",
            multiline = false,
            rows = 4,
            size = "medium",
            className = "",
            textFieldClassName = "",
            labelTextClassName = "",
            require = false,
            helperClassName = "",
            ...rest
        },
        ref,
    ) => {
        const id = useId();
        const inValidClassName = inValid ? styles.error : "";
        const validClassName = valid ? styles.success : "";
        return (
            <Box className={`${styles.wrapper} ${inValidClassName} ${validClassName} ${className}`}>
                {label && (
                    <label htmlFor={id} className={`${styles.label} ${labelTextClassName}`}>
                        {label}{" "}
                        {require && <span style={{ color: "var(--danger-color)" }}>{" *"}</span>}
                    </label>
                )}
                <MUITextField
                    type={type}
                    className={`${styles["text-field"]} ${textFieldClassName}`}
                    inputRef={ref}
                    error={inValid}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    size={size}
                    InputProps={{
                        ...getDefaultInputProps(id),
                        ...(valid && {
                            endAdornment: (
                                <CheckCircleOutlineOutlinedIcon
                                    className={styles["success-icon"]}
                                />
                            ),
                        }),
                    }}
                    {...(multiline && {
                        multiline: true,
                        rows,
                    })}
                    {...rest}
                />
                {!!helperText && (
                    <div className={`${styles["helper-text"]} ${helperClassName}`}>
                        {/* <InfoOutlinedIcon /> */}
                        <p>{helperText}</p>
                    </div>
                )}
            </Box>
        );
    },
);

export { TextField };
