import AWS from "aws-sdk";

const generateFileName = (name = null) => {
    let timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    let random = ("" + Math.random()).substring(2, 8);
    if (name) {
        return name + "_" + timestamp + random;
    } else {
        return timestamp + random;
    }
};

export const imageUploadToAWS = async (file, isCountryAssets = false) => {
    try {
        if (!file) return { status: false, message: "File is Required" };
        // if (
        //   file.type === "image/png" ||
        //   file.type === "image/jpg" ||
        //   file.type === "image/jpeg" ||
        //   file.type === "image/svg"
        // ) {
        const allowedImageTypes = ["image/png", "image/jpg", "image/jpeg", "image/svg+xml"];
        if (!allowedImageTypes.includes(file.type)) {
            return { status: false, message: "File type not allowed" };
        }
        let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        if (sizeInMB > 2) {
            return { status: false, message: "Image should be less than 2MB" };
        }
        const S3_BUCKET = process.env.REACT_APP_IMAGE_UPLOAD_S3_BUCKET;
        const REGION = process.env.REACT_APP_IMAGE_UPLOAD_REGION;
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_IMAGE_UPLOAD_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_IMAGE_UPLOAD_SECRET_ACCESS_KEY_ID,
        });

        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const fileExt = file?.name?.split(".").pop();

        let tmpFilePath = `tmp/${generateFileName()}.${fileExt}`;

        if (isCountryAssets) {
            tmpFilePath = `gainserv_assets/flags/${generateFileName()}.${fileExt}`;
        }

        const params = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: tmpFilePath,
            // "Content-Type": file.type
        };

        const response = await new Promise((resolve, reject) => {
            myBucket.upload(params, (err, data) => {
                if (err) reject({ status: false, message: err });
                else
                    resolve({
                        status: true,
                        data: data,
                    });
            });
        });

        if (!response || !response.status) {
            return {
                status: false,
                message: response?.message || "Error From AWS S3",
            };
        } else {
            return {
                status: true,
                originalName: file?.name,
                data: response.data,
            };
        }
        // } else {
        //   return {
        //     status: false,
        //     message: "Image Should be jpg,png,or svg format",
        //   };
        // }
    } catch (error) {
        return {
            status: false,
            message: error?.message,
        };
    }
};

export const deleteImageFromAWS = async (src) => {
    try {
        const S3_BUCKET = process.env.REACT_APP_IMAGE_UPLOAD_S3_BUCKET;
        const REGION = process.env.REACT_APP_IMAGE_UPLOAD_REGION;
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_IMAGE_UPLOAD_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_IMAGE_UPLOAD_SECRET_ACCESS_KEY_ID,
        });

        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const AWSPath = "https://userlove-assets.s3.ap-south-1.amazonaws.com/";

        const deleteImagePath = src.replace(AWSPath, "");

        const params = {
            Bucket: S3_BUCKET,
            Key: deleteImagePath,
        };

        const response = await new Promise((resolve, reject) => {
            myBucket.deleteObject(params, (err, data) => {
                if (err) reject({ status: false, message: err });
                else
                    resolve({
                        status: true,
                        data: data,
                    });
            });
        });

        if (!response || !response.status) {
            return {
                status: false,
                message: response?.message || "Error From AWS S3",
            };
        } else {
            return {
                status: true,
                data: response.data,
                message: "Success",
            };
        }
    } catch (error) {
        return {
            status: false,
            message: error?.message,
        };
    }
};
