import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Observable } from "shared/Assets/JS/Utilities";
import { useCommonFunction, useGsForm } from "shared/Hooks";
import { clearCookie, getCookie, setCookie } from "shared/Assets/JS/Cookies";
import { GET_ALL_USER_PROFILE_ID } from "../graphql/UserProfile";
import { ProductContext } from "App";
import { LoginSchema } from "../schema/AuthenticationSchema";
import CryptoJS from "crypto-js";

const encryptPassword = (password) => {
    const encrypted = CryptoJS.AES.encrypt(
        password,
        process.env.REACT_APP_PASSWORD_SECRET_KEY,
    ).toString();

    return encrypted;
};

export const authObserver = new Observable();
window.authObserver = authObserver;

const loginCtx = createContext();

const SET_IS_AUTH = "SET_IS_AUTH";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_MESSAGE = "SET_MESSAGE";
const SET_RESEND_LOADING = "SET_RESEND_LOADING";
const SET_RESPONSE_DATA = "SET_RESPONSE_DATA";
const SET_IS_APP_LOADING = "SET_IS_APP_LOADING";
const SET_USER_DATA = "SET_USER_DATA";
const RESET_STATE = "RESET_STATE";
const SOCIAL_DATA = "SOCIAL_DATA";
const INVITATION_DATA = "INVITATION_DATA";
const INVITATION_LOADING = "INVITATION_LOADING";
const SET_REGISTER_LOADING = "SET_REGISTER_LOADING";
const SET_PASSWORD_DATA = "SET_PASSWORD_DATA";

const initialState = {
    isAppLoading: true,
    isAuth: false,
    isLoading: false,
    isResendLoading: false,
    responseData: null,
    userData: null,
    isNavigate: false,
    socialData: null,
    invitationData: null,
    invitationLoading: false,
    registerLoading: false,
    setPasswordData: null,
    message: {
        type: "error",
        text: "",
    },
};

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
    case SET_USER_DATA: {
        return {
            ...state,
            userData: payload,
        };
    }
    case INVITATION_DATA: {
        return {
            ...state,
            invitationData: payload,
        };
    }
    case SET_REGISTER_LOADING: {
        return {
            ...state,
            registerLoading: payload,
        };
    }
    case INVITATION_LOADING: {
        return {
            ...state,
            invitationLoading: payload,
        };
    }
    case SET_IS_LOADING: {
        return {
            ...state,
            isLoading: payload,
        };
    }
    case SET_IS_APP_LOADING: {
        return {
            ...state,
            isAppLoading: payload,
        };
    }
    case SET_RESPONSE_DATA: {
        return {
            ...state,
            responseData: payload,
        };
    }
    case SET_RESEND_LOADING: {
        return {
            ...state,
            isResendLoading: payload,
        };
    }
    case SET_IS_AUTH: {
        return {
            ...state,
            isAuth: payload,
        };
    }
    case SET_MESSAGE: {
        return {
            ...state,
            message: {
                ...state.message,
                ...payload,
            },
        };
    }
    case SOCIAL_DATA: {
        return { ...state, socialData: payload };
    }
    case SET_PASSWORD_DATA: {
        return {
            ...state,
            setPasswordData: payload,
        };
    }
    case RESET_STATE: {
        return { ...initialState };
    }
    default:
        return {
            ...state,
        };
    }
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { notify } = useCommonFunction();
    const { noDataOnError, setNoDataOnError, setOpenExtendTrial } = useContext(ProductContext);

    const methods = useGsForm({
        resolver: LoginSchema,
        defaultValues: {
            remember_me: false,
        },
    });
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = methods;
    const navigate = useNavigate();

    const SetUserData = (data) => {
        dispatch({
            type: SET_USER_DATA,
            payload: data,
        });
    };

    const setRegisterLoading = (isLoading) => {
        dispatch({
            type: SET_REGISTER_LOADING,
            payload: isLoading,
        });
    };

    const setIsAppLoading = (isLoading) => {
        dispatch({
            type: SET_IS_APP_LOADING,
            payload: isLoading,
        });
    };

    const setInvitationData = (data) => {
        dispatch({
            type: INVITATION_DATA,
            payload: data,
        });
    };
    const setInvitationLoading = (data) => {
        dispatch({
            type: INVITATION_LOADING,
            payload: data,
        });
    };

    const setSocialData = (data) => {
        dispatch({
            type: SOCIAL_DATA,
            payload: data,
        });
    };
    const setMessage = (type, text) => {
        dispatch({
            type: SET_MESSAGE,
            payload: {
                type,
                text,
            },
        });
    };

    const setResponseData = (data) => {
        dispatch({
            type: SET_RESPONSE_DATA,
            payload: data,
        });
    };

    const setLoading = (isLoading) => {
        dispatch({
            type: SET_IS_LOADING,
            payload: isLoading,
        });
    };
    const setResendLoading = (isLoading) => {
        dispatch({
            type: SET_RESEND_LOADING,
            payload: isLoading,
        });
    };

    const clearMessage = useCallback(() => {
        dispatch({
            type: SET_MESSAGE,
            payload: {
                type: "",
                text: "",
            },
        });
    }, []);

    // const logout = useCallback(() => {
    //     clearCookie();
    //     dispatch({
    //         type: RESET_STATE,
    //     });
    //     console.log(234, RESET_STATE);
    //     window.location.reload();
    // }, []);

    // useEffect(() => {
    //     const unSub = authObserver.subscribe(logout);
    //     return unSub;
    // }, [logout]);

    ////----------------------------------CHECK USER LOGIN----------------------------------*//
    const [getProfileData] = useLazyQuery(GET_ALL_USER_PROFILE_ID, {
        fetchPolicy: "network-only",
        onCompleted: (item) => {
            setIsAppLoading(false);
            setRegisterLoading(false);
            setOpenExtendTrial(item.getUserByLoginSession.data.trial_expired);

            dispatch({
                type: SET_IS_AUTH,
                payload: true,
            });
            // dispatch({
            //   type: SET_USER_DATA,
            //   payload: item.getUserByLoginSession.data,
            // });
            SetUserData(item.getUserByLoginSession.data);
            // window.location.reload();
        },
        onError: () => {
            setIsAppLoading(false);
            setNoDataOnError(true);
        },
        // Used for subsequent executions
    });
    ////----------------------------------CHANGE SUB DOMAIN----------------------------------*//
    const changeSubDomain = () => {
        let currentHost = process.env.REACT_APP_HOST;
        // const sub_domain = getCookie("sub_domain");
        // if (sub_domain) {
        //     currentHost = currentHost.replace("%%subdomain%%", sub_domain);
        // } else currentHost = currentHost.replace("%%subdomain%%.", sub_domain);
        window.location.hostname = currentHost;
    };

    ////----------------------------------CHECK USER LOGIN----------------------------------*//
    const checkUserLove = () => window.location.hostname === process.env.REACT_APP_DOMAIN;

    let domain = getCookie("admin_sub_domain").toLowerCase() === "gainserv" ? "admin" : "gainserv";

    //----------------------------------CHECK SUB DOMAIN----------------------------------*//
    const hasSubDomain = () =>
        // getCookie("sub_domain") && window.location.host.split(".")[0] === getCookie("sub_domain");
        getCookie("admin_sub_domain") && window.location.hostname.split(".")[0] === domain;

    //!------------------------------------------------------------------------------------*//
    //!----------------------------------CHECK USER LOGIN----------------------------------*//
    //!------------------------------------------------------------------------------------*//

    useEffect(() => {
        const access_token = getCookie("admin_access_token");
        const refresh_token = getCookie("admin_refresh_token");
        // const sub_domain = getCookie("admin_sub_domain");
        if (access_token && refresh_token && domain) {
            if (hasSubDomain()) {
                getProfileData();
            } else {
                clearCookie();
                setIsAppLoading(false);
                navigate("/login");
                notify("error", "Email doesn't exist");
                return;
            }
        } else {
            clearCookie();
            setIsAppLoading(false);
            if (checkUserLove()) return;
            changeSubDomain();
        }
    }, []);

    const handleAuthSuccess = (userData) => {
        const { access_token, refresh_token, sub_domain, is_navigate, currency } = userData;
        setCookie("admin_access_token", access_token, 100);
        setCookie("admin_refresh_token", refresh_token, 100);
        setCookie("admin_sub_domain", sub_domain, 100);
        setCookie("admin_currency", currency, 100);
        setCookie("admin-user-data", userData, 100);

        if (is_navigate) {
            if (hasSubDomain()) getProfileData();
            else changeSubDomain();
        } else {
            if (hasSubDomain()) getProfileData();
            else changeSubDomain();
        }
    };
    //*----------------------------------Handle  Login----------------------------------*//

    const handleLogin = async (data) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/login`,
                {
                    email_id: data.email_id,
                    password: encryptPassword(data.password),
                    remember_me: data.remember_me ? data.remember_me : false,
                    grant_type: "admin",
                },
                { withCredentials: true },
            );

            if (response?.data?.status) {
                setResponseData(response);
                setLoading(false);
                const userData = response.data.data;

                const { two_fa_status, emailVerification } = userData;

                if (emailVerification) {
                    setMessage("success", response?.data?.message);

                    navigate("/otp-conformation", {
                        state: userData,
                    });
                    return;
                }

                if (two_fa_status) {
                    setMessage("success", response?.data?.message);

                    navigate("/two-factor-auth", {
                        state: userData,
                    });
                    return;
                }
                // setMessage("success", response?.data?.message);

                handleAuthSuccess(userData);
            } else {
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setLoading(false);
            setResponseData(error.response?.data);
            setMessage("error", error.response?.data?.message || error.message);
        }
    };
    //*----------------------------------HANDLE TWO-FA VERIFICATION----------------------------------*//
    const handleTwoFAVerification = async (data, Otp) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/twofa-login`,
                {
                    otp: Otp.otp1 + Otp.otp2 + Otp.otp3 + Otp.otp4 + Otp.otp5 + Otp.otp6,
                    two_factor_token: data.two_factor_token,
                },
                { withCredentials: true },
            );
            if (response?.data?.status) {
                const userData = response.data.data;
                setLoading(false);
                handleAuthSuccess(userData);
            }
        } catch (error) {
            setLoading(false);
            setMessage("error", error.response?.data?.message || error.message);
        }
    };
    //*----------------------------------HANDLE RESEND  TWO-FA VERIFICATION----------------------------------*//
    const handleResendTwoFA = async (data) => {
        setResendLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/twofa-resend-otp`,
                {
                    two_factor_token: data.two_factor_token,
                },
                { withCredentials: true },
            );
            if (response?.data?.status) {
                setResendLoading(false);
                setMessage("success", response?.data?.message);
            } else {
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setResendLoading(false);
            setMessage("error", error.response?.data?.message || error.message);
        }
    };

    //*----------------------------------Email OTP Verification----------------------------------*//
    const handleOtpVerification = async (Otp, data) => {
        setRegisterLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/email-verification-otp`,
                {
                    otp: Otp.otp1 + Otp.otp2 + Otp.otp3 + Otp.otp4 + Otp.otp5 + Otp.otp6,
                    email_verification_token: data.email_verification_token,
                },
                { withCredentials: true },
            );
            if (response?.data?.status) {
                const userData = response.data.data;

                handleAuthSuccess(userData);
                // setMessage("success", response?.data?.message);
            } else {
                setRegisterLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setRegisterLoading(false);
            setMessage("error", error.response?.data?.message || error.message);
        }
    };

    //*---------------------------------Resend Email OTP----------------------------------*//
    const handleResendEmailOtp = async (data) => {
        setResendLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/resend-email-verification-otp`,
                {
                    email_verification_token: data.email_verification_token,
                },
                { withCredentials: true },
            );
            if (response?.data?.status) {
                setResendLoading(false);

                setMessage("success", response?.data?.message);
            }
        } catch (error) {
            setResendLoading(false);

            setMessage("error", error.response?.data?.message || error.message);
        }
    };

    //*----------------------------------LOGIN WITH OTP FUNCTION HANDLER----------------------------------*//
    const handleOtpLogin = (data, state, otp, responseData) => {
        state?.responseData?.data?.loginWithOTP
            ? handleGetOtp(data)
            : handleLoginOtpVerification(otp, responseData);
    };

    //*----------------------------------Get OTP Handler----------------------------------*//
    const handleGetOtp = async (data) => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/send-otp`,
                {
                    email_id: data.email_id,
                    grant_type: "admin",
                },
                { withCredentials: true },
            );
            if (response?.data?.status) {
                setLoading(false);
                setResponseData(response);
                setMessage("success", response?.data?.message);
            } else {
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
            navigate("/otp-login", { state: response.data });
        } catch (error) {
            setLoading(false);

            setMessage("error", error.response?.data?.message || error.message);
        }
    };

    //*----------------------------------LOG IN WITH OTP VERIFICATION----------------------------------*//
    const handleLoginOtpVerification = async (Otp, responseData) => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/login-with-otp`,
                {
                    email_id: responseData.data.data.email_id,
                    otp: Otp.otp1 + Otp.otp2 + Otp.otp3 + Otp.otp4 + Otp.otp5 + Otp.otp6,
                    remember_me: true,
                    grant_type: "admin",
                },
                { withCredentials: true },
            );
            if (response?.data?.status) {
                setLoading(false);

                const userData = response.data.data;

                setLoading(false);
                handleAuthSuccess(userData);
            } else {
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setLoading(false);
            setMessage("error", error.response?.data?.message || error.message);
        }
    };

    //*--------------------------------------HANDLE RESEND OTP OF OTP LOGIN ----------------------------------------*/
    const handleResendOtp = async (dataItems) => {
        setResendLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/resend-otp`,
                {
                    email_id: dataItems,
                    grant_type: "admin",
                },
                { withCredentials: true },
            );
            if (response?.data?.status) {
                setResendLoading(false);
                setResponseData(response);
                navigate("/otp-login", { state: response.data });

                setMessage("success", response?.data?.message);
            } else {
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setResendLoading(false);

            setMessage("error", error.response?.data?.message || error.message);
        }
    };
    //*-------------------> FORGOT FORM HANDLER <------------------------------
    const handleForgotPassword = async (dataItems) => {
        setLoading(true);
        if (dataItems.email_id) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_LOGIN_SERVER}/user/forgot-password`,
                    {
                        email_id: dataItems.email_id,
                        grant_type: "admin",
                    },
                    { withCredentials: true },
                );
                if (response?.data?.status) {
                    setLoading(false);
                    setMessage("success", response?.data?.message);
                } else {
                    setLoading(false);
                    setMessage("error", response?.data?.message);
                }
            } catch (error) {
                setLoading(false);

                setMessage("error", error.response?.data?.message || error.message);
            }
        }
    };
    //?--------------------> RESET FORM HANDLER <------------------------------
    const handleResetPassword = async (dataItems, params) => {
        setLoading(true);
        if (dataItems.password === dataItems.confirm_password) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_LOGIN_SERVER}/user/reset-password`,
                    {
                        password: encryptPassword(dataItems.password),
                        token: params && params.token,
                        grant_type: "admin",
                    },
                    { withCredentials: true },
                );
                if (response?.data?.status) {
                    setLoading(false);
                    setMessage("success", response?.data?.message);
                    navigate("/login");
                } else {
                    setLoading(false);
                    setMessage("error", response?.data?.message);
                }
            } catch (error) {
                setLoading(false);

                setMessage("error", error.response?.data?.message || error.message);
            }
        } else {
            setLoading(false);
            setMessage("error", "Password and confirm password should be same");
        }
    };

    // const [extendTrial, { loading: extendTrialLoading }] = useMutation(
    //   EXTEND_TRIAL,
    //   {
    //     refetchQueries: [
    //       {
    //         query: GET_ALL_USER_PROFILE_ID,
    //       },
    //       {
    //         query: GET_ALL_USERS_PROFILE_DATA,
    //       },
    //     ],
    //     onCompleted: (item) => {
    //       if (item.extendTrial.status) {
    //         setOpenExtendTrial(false);
    //         notify("info", item.extendTrial.message);
    //       } else {
    //         notify("error", item.extendTrial.message);
    //       }
    //     },
    //     onError: (error) => {
    //
    //     },
    //   }
    // );

    //!------------------------------------------------------------------------------------*//
    //!----------------------------------REGISTER USER FUNCTION----------------------------*//
    //!------------------------------------------------------------------------------------*//

    //?----------------------------------REGISTER USER FUNCTION HANDLER----------------------------------*//
    const handleRegister = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get("https://geolocation-db.com/json/");
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/user-register`,
                {
                    email_id: data.email_id,
                    password: data.password,
                    user_name: data.user_name,
                    company_name: data.company_name,
                    ip_address: data?.IPv4,
                },
            );
            if (response?.data?.status) {
                setLoading(false);
                // setMessage("success", response?.data?.message);
                // navigate("/login");
                setResponseData(response);
                setMessage("success", response?.data?.message);
                setLoading(false);
                const userData = response.data.data;

                const { emailVerification } = userData;

                if (emailVerification) {
                    navigate("/email-verification", {
                        state: userData,
                    });
                    return;
                }
            } else {
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setLoading(false);

            setMessage("error", error.response?.data?.message || error.message);
        }
    };

    //?---------------------------------Resend Email OTP----------------------------------*//
    const handleResendEmailVerifyOtp = async (data) => {
        setResendLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/resend-email-verification-otp`,
                {
                    email_verification_token: data.email_verification_token,
                },
                { withCredentials: true },
            );
            setMessage("success", response?.data?.message);
        } catch (error) {
            setResendLoading(false);

            setMessage("error", error.response?.data?.message || error.message);
        }
    };

    //!----------------------------------------------------------------------------------*//
    //!------------------------------SOCIAL LOGIN &B REGISTER----------------------------*//
    //!----------------------------------------------------------------------------------*//

    //?---------------------------------GOOGLE LOGIN----------------------------------*//
    const handleSocialGoogleLogin = async (data) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/social-login`,
                {
                    type: "google",
                    idToken: data._token.idToken,
                },
            );
            if (response?.data?.status) {
                setResponseData(response);
                setLoading(false);
                const userData = response.data.data;
                setSocialData(userData);

                const { two_fa_status, access_token, maxOTPSendingAttempts } = userData;

                if (two_fa_status) {
                    setMessage("success", response?.data?.message);

                    navigate("/two-factor-auth", {
                        state: userData,
                    });
                    return;
                }
                if (maxOTPSendingAttempts) {
                    setMessage("error", response?.data?.message);
                    return;
                }
                if (!access_token) {
                    navigate("/register", { state: userData });
                    return;
                }

                handleAuthSuccess(userData);
            } else {
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setLoading(false);
            setMessage("error", error.response?.data?.message);
        }
    };

    //?---------------------------------GOOGLE REGISTER----------------------------------*//

    const handleSocialGoogleRegister = async () => {
        // setLoading(true);
        setRegisterLoading(true);
        try {
            const { data } = await axios.get("https://geolocation-db.com/json/");
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/social-registration`,
                {
                    user_name: data?.user_name,
                    email_id: data?.email_id,
                    profile_picture: data?.profile_picture,
                    company_name: data?.company_name,
                    ip_address: data?.IPv4,
                },
            );
            if (response?.data?.status) {
                setResponseData(response);
                setLoading(false);
                const userData = response.data.data;

                handleAuthSuccess(userData);
                const { two_fa_status, access_token, maxOTPSendingAttempts } = userData;

                if (!access_token) {
                    setSocialData(userData);

                    navigate("/register");
                    return;
                }
                if (maxOTPSendingAttempts) {
                    setMessage("error", response?.data?.message);
                    return;
                }
                if (two_fa_status) {
                    navigate("/two-factor-auth", {
                        state: userData,
                    });
                    return;
                }
                // const { emailVerification } = userData;

                // if (emailVerification) {
                //   navigate("/email-verification", {
                //     state: userData,
                //   });
                //   return;
                // }
            } else {
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setLoading(false);
            setMessage("error", error.response?.data?.message);
        }
    };

    const handleGoogleRegister = async (data) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/social-registration-attempt`,
                {
                    type: "google",
                    idToken: data._token.idToken,
                },
            );
            if (response?.data?.status) {
                // setMessage("success", response?.data?.message);
                setLoading(false);
                const userData = response.data.data;

                const { two_fa_status, access_token } = userData;

                if (two_fa_status) {
                    setMessage("success", response?.data?.message);

                    navigate("/two-factor-auth", {
                        state: userData,
                    });
                    return;
                }
                if (!access_token) {
                    setSocialData(userData);
                    return;
                }
                handleAuthSuccess(userData);
            } else {
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setLoading(false);
            setMessage("error", error.response?.data?.message);
        }
    };

    //?---------------------------------MICROSOFT LOGIN----------------------------------*//
    const handleMicrosoftLogin = async (err, data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/social-login`,
                {
                    type: "microsoft",
                    idToken: data.idToken.rawIdToken,
                },
            );
            if (response?.data?.status) {
                setResponseData(response);
                // setMessage("success", response?.data?.message);
                setLoading(false);
                const userData = response.data.data;

                const { two_fa_status, access_token } = userData;

                if (two_fa_status) {
                    setMessage("success", response?.data?.message);

                    navigate("/two-factor-auth", {
                        state: userData,
                    });
                    return;
                }
                if (!access_token) {
                    navigate("/register", { state: userData });
                    return;
                }

                handleAuthSuccess(userData);
            } else {
                sessionStorage.clear();
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            setLoading(false);
            setMessage("error", error.response?.data?.message);
        }
    };
    //?---------------------------------MICROSOFT REGISTER----------------------------------*//
    const handleMicrosoftRegister = async (err, data) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/social-registration-attempt`,
                {
                    type: "microsoft",
                    idToken: data.idToken.rawIdToken,
                },
            );
            if (response?.data?.status) {
                sessionStorage.clear();

                setLoading(false);
                const userData = response.data.data;

                const { two_fa_status, access_token } = userData;

                if (two_fa_status) {
                    setMessage("success", response?.data?.message);

                    navigate("/two-factor-auth", {
                        state: userData,
                    });
                    return;
                }
                if (!access_token) {
                    setSocialData(userData);
                    return;
                }
                handleAuthSuccess(userData);
            } else {
                sessionStorage.clear();
                setLoading(false);
                setMessage("error", response?.data?.message);
            }
        } catch (error) {
            sessionStorage.clear();

            setLoading(false);
            setMessage("error", error.response?.data?.message);
        }
    };

    //?--------------------> GET INVITATION DATA<------------------------------

    const getDataofInvitation = async (props) => {
        setInvitationLoading(true);
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/invitation-token-data`,
                {
                    invitation_token: props?.token,
                },
                { withCredentials: true },
            );
            if (data?.status) {
                setInvitationLoading(false);
                setInvitationData(data?.data);
            } else {
                setInvitationLoading(false);
                notify("error", data?.message || "Internal server error");
                setMessage("error", data?.message);
            }
        } catch (error) {
            setInvitationLoading(false);
            notify("error", error.response?.data?.message || error.message);
            setMessage("error", error.response?.data?.message || error.message);
        }
    };
    //?--------------------> HANDLE ACCEPT INVITATION<------------------------------
    const handleAcceptinvitation = async (props) => {
        setLoading(true);

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/invitation-link`,
                {
                    invitation_token: props?.token,
                },
                { withCredentials: true },
            );
            if (data?.status === true) {
                setLoading(false);

                setMessage(data?.message);

                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            }
        } catch (error) {
            notify("error", error.response?.data?.message || error.message);
            setMessage("error", error.response?.data?.message || error.message);
        }
    };
    //?--------------------> SET FORM HANDLER <------------------------------
    const handleSetPassword = async (dataItems, params) => {
        if (dataItems.confirm_password === dataItems.password) {
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_LOGIN_SERVER}/user/set-password`,
                    {
                        password: encryptPassword(dataItems.password),
                        first_name: dataItems.first_name,
                        last_name: dataItems.last_name,
                        set_password_token: params && params.token,
                        grant_type: "admin",
                    },
                    { withCredentials: true },
                );
                if (res.data.status) {
                    setLoading(true);
                    navigate("/login");
                    setMessage("success", res.data.message);
                    let currentHost = process.env.REACT_APP_HOST;
                    window.location.host = currentHost;
                    setLoading(false);
                } else {
                    setMessage("success", res.data.message);
                }
            } catch (error) {
                setMessage("error", error.response?.data?.message || error.message);
            }
        } else {
            setMessage("error", "Password Doesn't match.");
        }
    };

    const getUserDataForSetPassword = async (params) => {
        if (params.token) {
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_LOGIN_SERVER}/user/set-password-data`,
                    {
                        token: params && params.token,
                        grant_type: "admin",
                    },
                    { withCredentials: true },
                );
                if (res.data.status) {
                    dispatch({
                        type: SET_PASSWORD_DATA,
                        payload: res.data.data,
                    });
                }
            } catch (error) {
                notify("error", error.message);
            }
        }
    };

    // const [extendTrial, { loading: extendTrialLoading }] = useMutation(
    //   EXTEND_TRIAL,
    //   {
    //     refetchQueries: [
    //       {
    //         query: GET_ALL_USER_PROFILE_ID,
    //       },
    //       {
    //         query: GET_ALL_USERS_PROFILE_DATA,
    //       },
    //     ],
    //     onCompleted: (item) => {
    //       if (item.extendTrial.status) {
    //         setOpenExtendTrial(false);
    //         notify("info", item.extendTrial.message);
    //       } else {
    //         notify("error", item.extendTrial.message);
    //       }
    //     },
    //     onError: (error) => {
    //
    //     },
    //   }
    // );

    return (
        <loginCtx.Provider
            value={{
                ...state,
                handleLogin,
                handleSetPassword,
                getDataofInvitation,
                handleAcceptinvitation,
                handleSocialGoogleRegister,
                handleSocialGoogleLogin,
                handleGoogleRegister,
                handleOtpVerification,
                handleResendEmailOtp,
                handleTwoFAVerification,
                clearMessage,
                handleResendTwoFA,
                handleOtpLogin,
                handleResendOtp,
                handleForgotPassword,
                handleResetPassword,
                handleRegister,
                handleResendEmailVerifyOtp,
                handleMicrosoftLogin,
                setSocialData,
                // logout,
                handleMicrosoftRegister,
                SetUserData,
                noDataOnError,
                register,
                handleSubmit,
                setValue,
                watch,
                errors,
                methods,
                // extendTrial,
                // extendTrialLoading,
                getUserDataForSetPassword,
            }}
        >
            {children}
        </loginCtx.Provider>
    );
};

export const useLogin = () => useContext(loginCtx);
