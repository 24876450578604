import { useEffect } from "react";

const useAutoClose = (message, cb, timeout = 3000) => {
    useEffect(() => {
        let id;
        if (message.text)
            id = setTimeout(() => {
                cb();
            }, timeout);

        return () => {
            clearTimeout(id);
        };
    }, [cb, timeout, message]);

    useEffect(() => {
        return cb;
    }, [cb]);
};

export default useAutoClose;
