import React from "react";
import { Outlet } from "react-router-dom";
import { Footer, Navbar, Sidebar } from "shared/Components";

const Layout = () => {
    return (
        <>
            <Navbar />
            <div className="gs-inner-main-wrapper">
                <Sidebar />
                <div className="gs-content-footer-wrapper">
                    <div className="gs-content-wrapper">
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Layout;
