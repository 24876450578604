import { styled } from "@mui/system";
import React from "react";

import { MUISwitch } from "..";
import { CircularProgress } from "@mui/material";

const times = {
    small: 0.8,
    medium: 1,
    large: 1.5,
};

const StyledSwitch = styled(MUISwitch)(({ size }) => ({
    width: 48 * times[size],
    height: 28 * times[size],
    padding: 0,
    display: "flex",
    borderRadius: (28 * times[size]) / 2,
    "& .MuiSwitch-switchBase": {
        padding: 2 * times[size],
        "&.Mui-checked": {
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: "var(--primary-color)",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        width: 24 * times[size],
        height: 24 * times[size],
        borderRadius: (24 * times[size]) / 2,
        boxShadow: "none",
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "var(--border-color)",
        boxSizing: "border-box",
    },
}));

export const Switch = ({
    size = "large",
    checked,
    onChange,
    defaultChecked,
    loading = false,
    ...props
}) => {
    if (loading) {
        return <CircularProgress size={20} sx={{ color: "var(--primary-color)" }} />;
    }
    return (
        <StyledSwitch
            size={size}
            checked={checked}
            onChange={onChange}
            defaultChecked={defaultChecked}
            {...props}
        />
    );
};
